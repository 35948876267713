<template>
  <div class="SwitchableStatement">
    <p
      v-if="$te(labeli18n)"
      class="label"
    >
      {{ $t(labeli18n) }}
    </p>

    <ul class="choices">
      <li
        v-for="(choice, i) in $attrs.choices"
        :key="i"
        class="choice"
        :class="{active: (i === 0)}"
        @click="selectChoice(i, $event)"
      >
        {{ $t(`STATEMENT.${$attrs.name}.CHOICE.${choice}`) }}
      </li>
    </ul>

    <div class="boxes">
      <SimpleStatement
        v-for="(choice, i) in $attrs.choices"
        :key="i"
        class="box"
        :class="{active: (i === 0)}"
        v-bind="{name: `${$attrs.name}.CHOICETEXT.${choice}`, image: getImage(i)}"
      />
    </div>
  </div>
</template>

<script>
import SimpleStatement from '@/components/statements/SimpleStatement';

export default {
  components: {
    SimpleStatement,
  },

  computed: {
    labeli18n() {
      return `STATEMENT.${this.$attrs.name}.LABEL`;
    },
  },

  methods: {
    getImage(i) {
      return this.$attrs?.images[i];
    },

    selectChoice(i, { target }) {
      const choice = target.closest('.choice');
      this.activeChoice(choice);

      const box = this.$el.querySelector(`.box:nth-child(${(i + 1)})`);
      this.activeChoiceBox(box);
    },

    activeChoice(choice) {
      const activeChoice = this.$el.querySelector('.choice.active');
      activeChoice.classList.remove('active');

      if (!choice.classList.contains('active')) {
        choice.classList.add('active');
      }
    },

    activeChoiceBox(box) {
      const boxChoice = this.$el.querySelector('.box.active');
      boxChoice.classList.remove('active');

      if (!box.classList.contains('active')) {
        box.classList.add('active');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SwitchableStatement{
  display: flex;
  // flex-wrap: wrap;

  .choices{
    flex: 0 0 300px;
    display: grid;
    padding: 0;

    .choice{
      padding: $spacing-s $spacing-m;
      margin: 0;
      background: $color-neutral-lighter;
      display: grid;
      align-content: center;
      transition: all .3s ease;
      text-align: center;
      text-transform: uppercase;

      &:not(:last-child){
        border-bottom: 1px solid $color-neutral-mid-dark;
      }

      &:not(.active){
        border-right: 1px solid $color-neutral-mid-dark;
      }

      &:hover{
        box-shadow: inset 0 0 0 2px $color-primary-lighter;
        cursor: pointer;
      }

      &.active{
        box-shadow: inset 0 0 0 2px $color-primary;
        background: $color-primary-lighter;
        cursor: pointer;
      }
    }
  }

  .boxes{
    flex: 1;
    background: #fff;
    display: flex;

    max-height: 500px;
    ::v-deep .text{
      line-height: 1.5;
      overflow-y: auto;
      padding: $spacing-l;
    }

    .box{
      flex: 1;

      &:not(.active){
        display: none;
      }
    }
  }

  @media #{$breakpoint-lg-max} {
    flex-direction: column;

    .choices{
      flex: 0;
    }
  }
}
</style>
