/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  reports: undefined,
  users: undefined,
  user: undefined,
  loading: false,
  forcedReloading: false,
});

const state = () => getDefaultState();

export const getters = {
  getForcedReloading: state => state.forcedReloading,

  isLoading: state => state.loading,

  getReports: state => () => state.reports,

  getReport: state => reportId => state.reports.find(report => report.id === reportId),

  getUsers: state => () => state.users,

  getClient: state => () => state.user,
};

export const mutations = {
  ...make.mutations(state),

  FORCED_RELOADING(state) {
    state.forcedReloading = !state.forcedReloading;
  },

  SET_REPORT(state, newReport) {
    const reportIndex = state.reports.findIndex(report => parseInt(report.id, 10) === parseInt(newReport.id, 10));
    state.reports[reportIndex] = newReport;
  },

  ADD_REPORT(state, newReport) {
    const reports = state.reports.filter(report => parseInt(report.id, 10) !== parseInt(newReport.id, 10));
    state.reports = [...reports, newReport];
  },
};

export const actions = {
  async fetchClients({ commit }, userId) {
    try {
      commit('SET_LOADING', true);

      console.log(`/clients/${userId}`);
      const { data, error } = await axios.get(`/clients/${userId}`);
      if (error) throw new Error(data);

      commit('SET_USERS', data.users);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchClient({ commit }, userId) {
    try {
      commit('SET_LOADING', true);

      if (!userId) throw new Error('Error: Undefined user');

      const { data, error } = await axios.get(`/client/${userId}`);
      if (error) throw new Error(data);

      commit('SET_USER', data.user);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchReports({ commit }, userId) {
    try {
      commit('SET_LOADING', true);

      console.log('userId', userId);
      const { data, error } = await axios.get(`/user-reports/${userId}`);
      console.log(data, error);
      if (error) throw new Error(data);

      commit('SET_REPORTS', data.reports);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchReport({ commit }, reportId) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/report/${reportId}`);
      if (error) throw new Error(data);

      commit('SET_REPORT', data.report);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async saveReportInfo({ commit }, { report, userId }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/report-info', { report, userId });
      if (error) throw new Error(data);

      const commitHandler = (report.id) ? 'SET_REPORT' : 'ADD_REPORT';
      commit(commitHandler, data.report);
      commit('FORCED_RELOADING');

      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
