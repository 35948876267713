<template>
  <div class="ValidationButton">
    <BaseButton
      v-if="!isLastAttempts(currentAnswer)"
      variation="secondary"
      @click="check"
    >
      Comprobar
    </BaseButton>

    <slot name="error" />

    <div
      v-if="isLastAttempts(currentAnswer)"
      class="fail-message"
      v-html="$t(`MESSAGE.VALIDATION.${answerTag}.LASTFAIL`)"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [ModelMixin, i18nAttributeMixin],

  data() {
    return {
      answer: undefined,
    };
  },

  computed: {
    ...get('itinerariesModule', [
      'getAnswerByQuestionKey',
    ]),

    currentAnswer() {
      return this.answer ?? this.innerValue;
    },

    questionsKeys() {
      return this.$attrs?.questions;
    },

    attempts() {
      return this.$attrs?.attempts;
    },

    methodKey() {
      return this.methodName.toUpperCase();
    },

    isDisabled() {
      return this.isLastAttempts(this.value);
    },
  },

  methods: {
    async check() {
      const answers = this.questionsKeys.map(questionKey => this.getAnswerByQuestionKey(questionKey));
      const data = { result: this.$attrs.name, answers };
      const { answer } = await this.$store.dispatch('itinerariesModule/answersValidation', data);
      this.answer = answer;
      this.showMessage(answer);
      if (this.isLastAttempts(answer)) {
        this.innerValue = answer;
        this.disableQuestionsValidated();
      }
    },

    showMessage(answer) {
      const isCorrect = answer[0];
      if (isCorrect) {
        this.$toast.success(this.$t(`MESSAGE.VALIDATION.${this.answerTag}.SUCCESS`));
      } else if (!this.isLastAttempts(answer)) {
        this.$toast.error(this.$t(`MESSAGE.VALIDATION.${this.answerTag}.FAIL`));
      }
    },

    isLastAttempts(answer) {
      if (!Array.isArray(answer) || typeof answer[0] === 'undefined' || typeof answer[1] === 'undefined') return false;

      const isCorrect = answer[0];
      const attempt = answer[1];
      return isCorrect || (!isCorrect && attempt >= this.attempts);
    },

    disableQuestionsValidated() {
      this.questionsKeys.forEach((questionKey) => {
        const question = document.querySelector(`.question[name=${questionKey}]`);

        if (question.classList && !question.classList.contains('disabled')) {
          question.classList.add('disabled');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ValidationButton{
  .fail-message{
    margin-top: $spacing-m;
    background: #ffe5e5;
    padding: $spacing-m;


    ::v-deep ul {
      padding: $spacing-xs;
      padding-left: $spacing-xl;
      list-style: initial;

      li {
        margin-bottom: $spacing-xs;

        &::marker {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
