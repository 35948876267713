<template>
  <div class="MenuTooltipFooter">
    <a @click="gotoHome()">
      <font-awesome-icon :icon="['fas', 'home']" />
      <div>
        <p class="title">
          {{ $t('MENU.TOOLTIP.HOME') }}
        </p>

        <p class="subtitle">
          {{ $t('MENU.TOOLTIP.HOME') }}
        </p>
      </div>
    </a>

    <a @click="gotoProfile()">
      <font-awesome-icon :icon="['fas', 'user']" />
      <div>
        <p class="title">
          {{ $t('MENU.TOOLTIP.PROFILE') }}
        </p>

        <p class="subtitle">
          {{ $t('MENU.TOOLTIP.SETTINGS') }}
        </p>
      </div>
    </a>

    <a
      v-if="user && user.profile === 2"
      @click="gotoReport()"
    >
      <font-awesome-icon :icon="['fas', 'poll']" />
      <div>
        <p class="title">
          {{ $t('MENU.TOOLTIP.REPORT') }}
        </p>

        <p class="subtitle">
          {{ $t('MENU.TOOLTIP.RESULT') }}
        </p>
      </div>
    </a>

    <a @click="gotoHelp()">
      <font-awesome-icon :icon="['fas', 'question']" />
      <div>
        <p class="title">
          {{ $t('MENU.TOOLTIP.HELP') }}
        </p>

        <p class="subtitle">
          {{ $t('MENU.TOOLTIP.SECTION.HELP') }}
        </p>
      </div>
    </a>

    <a
      @click="logout()"
    >
      <font-awesome-icon :icon="['fas', 'door-open']" />
      <div>
        <p class="title">
          {{ $t('HEADER.LOGOUT.TITLE') }}
        </p>

        <p class="subtitle">
          {{ $t('HEADER.LOGOUT.DESCRIPTION') }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...get('messageModule', [
      'getMailsReceivedNotRead',
    ]),

    getMailsNotRead() {
      return this.getMailsReceivedNotRead()?.length;
    },
  },

  methods: {
    logout() {
      window.scrollTo(0, 0);
      this.$store.dispatch('authModule/logout');
    },

    gotoProfile() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Profile' });
    },

    gotoReport() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Reports' });
    },

    gotoHome() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Dashboard' });
    },

    gotoHelp() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Help' });
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuTooltipFooter{
  background: $color-primary-lightest;

  a{
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
    text-decoration: none;
    padding: $spacing-s $spacing-m;
    margin: 0;

    transition: all .5s ease;

    &:hover{
      background-color: $color-primary-lighter;
      color: $color-neutral-mid-darker;
      cursor: pointer;
    }

    .title{
      color: $color-primary-darkest;
      font-size: $font-size-s;
      margin-bottom: $spacing-2xs;
    }

    .subtitle{
      font-size: $font-size-xs;
      color: $color-neutral-mid-darker;
    }

    svg{
      width: 25px;
      color: $color-primary;
      margin-right: $spacing-s;
    }
  }
}
</style>
