var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.name,
        mode: "out-in",
        "enter-active-class": _vm.name + "-enter-active " + _vm.name,
        "leave-active-class":
          _vm.inverseName + "-leave-active " + _vm.inverseName,
        appear: ""
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }