/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';


export const USER_TOKEN_KEY = 'user-token';
export const USER_DATA = 'user-data';

const getDefaultState = () => ({
  token: localStorage.getItem(USER_TOKEN_KEY) || undefined,
  user: undefined,
  loading: false,
});

const state = () => getDefaultState();

export const getters = {
  isAuthenticated: state => !!state.token,

  isLoading: state => state.loading,

  getUser: state => () => state?.user,
};

const mutations = {
  ...make.mutations(state),

  SET_USER(state, user) {
    state.user = user;
    localStorage.setItem(USER_DATA, JSON.stringify(user));
  },

  AUTH_SUCCESS(state, { token }) {
    state.token = token;
  },

  AUTH_LOGOUT(state) {
    state.token = undefined;
  },

  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(USER_TOKEN_KEY, token);
  },

  // ACTIVE_USER(state) {
  //   state.user.active = true;
  // },
};

export const actions = {
  async login({ commit, dispatch }, { email, password }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/login', { email, password });
      if (!error) {
        const { token } = data;
        const { user } = data;
        // commit('AUTH_SUCCESS', data);
        commit('SET_TOKEN', token);
        commit('SET_USER', user);

        // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        axios.defaults.headers.common = Object.assign(axios.defaults.headers.common, { 'BEARER-TOKEN': token });
        dispatch('localeModule/changeLocale', user.locale, { root: true });

        window.Vue.$router.push({ name: 'Dashboard' });
      } else throw new Error(data);
    } catch (e) {
      localStorage.removeItem(USER_TOKEN_KEY);
      localStorage.removeItem(USER_DATA);

      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async register({ commit }, requestData) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/register', requestData);
      if (error) throw new Error(data);

      window.Vue.$toast.success(data);
      window.Vue.$router.push({ name: 'Login' });
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchUser({ commit, dispatch }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/user');
      if (error) throw new Error(data);

      dispatch('localeModule/changeLocale', data.user.locale, { root: true });
      commit('SET_USER', data.user);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async passwordRecover({ commit }, requestData) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/forgot-password', requestData);
      if (error) throw new Error(data);


      window.Vue.$toast.success(data);
      window.Vue.$router.push({ name: 'Login' });
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async logout({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/logout');
      if (error) throw new Error(data);

      commit('AUTH_LOGOUT');
      localStorage.removeItem(USER_TOKEN_KEY);
      localStorage.removeItem(USER_DATA);

      delete axios.defaults.headers.common.Authorization;
      // delete axios.defaults.headers.common['BEARER-TOKEN'];

      window.Vue.$toast.success(data);
      window.Vue.$router.push({ name: 'Login' });
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeLocale({ state, commit, dispatch }, locale) {
    try {
      const { data, error } = await axios.post('/change-locale', locale);
      if (!error) {
        const user = Object.assign(state.user, {
          locale: data.user.locale,
        });

        commit('SET_USER', user);

        dispatch('localeModule/changeLocale', user.locale, { root: true });

        window.Vue.$toast.success(data.message);
      } else throw new Error(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changePassword({ commit }, { actualPassword, newPassword }) {
    try {
      const { error, status, data } = await axios.post('/change-password', { actualPassword, newPassword });
      if (!error && status === 200) {
        window.Vue.$toast.success(data);
      } else if (!error && status === 304) {
        window.Vue.$toast.info(data);
      } else throw new Error(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeInformationUser({ state, commit }, user) {
    try {
      const { data, status, error } = await axios.post('/change-information-user', user);
      if (!error && status === 200) {
        const user = Object.assign(state.user, {
          name: data.user.name,
          last_name: data.user.last_name,
        });
        commit('SET_USER', user);
        window.Vue.$toast.success(data.message);
      } else if (!error && status === 304) {
        window.Vue.$toast.info(data);
      } else throw new Error(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeAvatar({ state, commit }, avatar) {
    try {
      const formData = new FormData();

      formData.append('file', avatar);

      const { error, status, data } = await axios.post('/change-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (!error && status === 200) {
        const user = Object.assign(state.user, { avatar: data?.user?.avatar });
        commit('SET_USER', user);
        window.Vue.$toast.success(data.message);
      } else if (!error && status === 304) {
        window.Vue.$toast.info(data.message);
      } else throw new Error(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // async activeUser({ commit }) {
  //   commit('ACTIVE_USER');
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
