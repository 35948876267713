<template>
  <div class="RouteStatement">
    <p
      v-if="$te(labeli18n)"
      class="label"
    >
      {{ $t(labeli18n) }}
    </p>

    <div class="buttons">
      <BaseButton
        v-for="(block, i) in blocks"
        :key="i"
        :variation="buttonType(i)"
        @click="$emit('force-redirect', block)"
      >
        {{ $t(`STATEMENT.${answerTag}.CHOICE.${i+1}`) }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [i18nAttributeMixin],

  computed: {
    labeli18n() {
      return `STATEMENT.${this.$attrs.name}.LABEL`;
    },

    blocks() {
      return this.$attrs.blocksId;
    },
  },

  methods: {
    buttonType(i) {
      return this.isLastButton(i) ? 'primary' : 'secondary';
    },

    isLastButton(i) {
      return (i === this.blocks.length - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.RouteStatement{
  .buttons{
    display: flex;
    gap: $spacing-xs;
    justify-content: center;
  }
}
</style>
