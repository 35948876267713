import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import authModule from './authModule';
import profileModule from './profileModule';
import reportsModule from './reportsModule';
import reportModule from './reportModule';
import programModule from './programModule';
import adminModule from './adminModule';

import localeModule from './localeModule';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],

  modules: {
    authModule,
    profileModule,
    reportsModule,
    reportModule,
    programModule,
    adminModule,
    localeModule,
  },
});
