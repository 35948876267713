<template>
  <div class="SimpleStatement">
    <div
      v-if="image"
      class="image"
      :style="{ 'background-image': 'url(' + image + ')'}"
    />

    <div
      class="text"
      v-html="$t(`STATEMENT.${$attrs.name}`)"
    />
  </div>
</template>

<script>
export default {
  computed: {
    image() {
      const image = this.$attrs?.image;
      if (!image) return undefined;
      // eslint-disable-next-line
      return require(`@/assets/images/form/${image}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.SimpleStatement{
  display: flex;
  flex-direction: column;

  .text{
    flex: 2;
    padding: $spacing-2xs;
  }

  .image{
    min-height: 200px;
    background: $color-neutral-light;
    background-size: cover;
    background-position: center;
    flex: 1;
  }

  @media #{$breakpoint-lg-min} {
    flex-direction: row-reverse;
  }
}
</style>
