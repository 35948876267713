<template>
  <div class="CalculatedStatement">
    <span class="result">
      {{ result || '--' }}
    </span>
    <span v-if="units">{{ units }}</span>
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';

export default {
  mixins: [ModelMixin],

  props: {
    answer: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    operationText() {
      return this.$attrs?.operation;
    },

    result() {
      const variables = this.operationText?.match(/([A-Z]+\w+\.*){3}/g) ?? [];
      const operation = variables.reduce((acc, variable) => {
        const value = (variable in this.answer) ? this.answer[variable] : 'X';
        return acc.replace(variable, value);
      }, this.operationText);

      // eslint-disable-next-line no-eval
      return !/X/g.test(operation) ? eval(operation) : undefined;
    },

    units() {
      return this.$attrs?.units;
    },

  },

  watch: {
    result(newResult) {
      this.innerValue = newResult;
    },
  },
};
</script>

<style lang="scss" scoped>
.CalculatedStatement{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  align-items: baseline;
  gap: $spacing-s;

  .result{
    text-align: right;
  }
}
</style>
