<template>
  <div
    class="SearcherSelect"
    :name="$attrs.name"
  >
    <div class="select-content">
      <div class="searcher">
        <BaseInput
          v-model="input.value"
          v-bind="input"
        />

        <span
          class="dropdown-button"
          :class="{open: choicesVisibility}"
          @click="toggleVisibility()"
        >
          <font-awesome-icon
            class="arrow"
            :icon="['fas', 'chevron-right']"
          />
        </span>
      </div>

      <ul
        v-if="choicesVisibility"
        class="options"
        :class="[`position--${position}`]"
      >
        <li
          v-for="(choice) in filteredChoices"
          :key="choice.key"
          class="option"
          :value="choice.key"
          @click="chooseOption(choice.key)"
          v-html="choice.value"
        />
      </ul>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  data() {
    return {
      input: {
        type: 'text',
        name: 'search',
        placeholder: 'Buscar_',
        value: undefined,
      },

      choicesVisibility: false,
      blocked: false,
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.input.value = this.choices[newValue - 1].value;
        this.$emit('input', newValue);
      },
    },

    range() {
      return this.$attrs.range;
    },

    position() {
      return this.$attrs?.position ?? 'relative';
    },

    name() {
      return this.$attrs.i18n || this.$attrs.name;
    },

    choices() {
      return this.$attrs.choices.map((i) => {
        const key = i;
        const value = this.$t(`QUESTION.${this.name}.CHOICE.${i}`);

        return { key, value };
      });
    },

    filteredChoices() {
      if (!this.input.value) {
        return this.choices;
      }

      const regexp = new RegExp(`(${this.input.value.toLowerCase()})`, 'im');
      const filteredChoices = this.filterChoices(regexp);
      return filteredChoices.map((choice) => {
        const value = this.underlineMatches(choice.value, regexp);
        return Object.assign({}, choice, { value });
      });
    },
  },

  watch: {
    input: {
      deep: true,
      handler() {
        if (!this.blocked) {
          this.choicesVisibility = true;
        }
      },
    },

    blocked(isBlocked) {
      if (isBlocked) {
        setTimeout(() => {
          this.blocked = false;
        }, 200);
      }
    },
  },

  created() {
    if (this.innerValue) {
      this.blocked = true;

      this.input.value = this.choices[this.innerValue - 1].value;
    }
  },

  methods: {
    chooseOption(optionNumber) {
      this.choicesVisibility = false;
      this.blocked = true;
      this.innerValue = optionNumber;
    },

    toggleVisibility() {
      this.choicesVisibility = !this.choicesVisibility;
    },

    filterChoices(neddle) {
      return this.choices.filter(choice => choice.value.match(neddle));
    },

    underlineMatches(haystack, neddle) {
      const match = haystack.match(neddle)[0];
      return haystack.replace(match, `<b>${match}</b>`);
    },
  },
};
</script>

<style lang="scss" scoped>
.SearcherSelect {
  max-width: 500px;

  .select-content{
    position: relative;
    display: grid;
  }

  .dropdown-button {
    display: flex;
    background-color: $color-primary;
    border-radius: 0 50px 50px 0;
    align-items: center;
    padding: $spacing-xs;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    justify-content: center;
    width: 50px;
    z-index: 2;

    &:hover{
      cursor: pointer;
    }

    .arrow {
      transition: all .3s ease;
      color: #fff;
    }

    &.open .arrow{
      transform: rotate(90deg);
    }
  }

  .searcher{
    position: relative;
    z-index: 2;
    .BaseInput ::v-deep input{
      padding-right: $spacing-3xl;
    }
  }

  .options{
    &.position{
      &--absoulte{
        position: absolute;
        top: 100%;
        right: $spacing-m;
        left: $spacing-m;
      }

      &--relative{
        position: relative;
        margin: auto;
        width: calc(100% - (2 * #{$spacing-m}));
      }
    }

    max-height: 300px;
    overflow-y: scroll;
    background: #fff;
    box-shadow: $shadow-elevation-2;

    .option{
      padding: $spacing-xs;
      border: 1px solid transparent;
      transition: all .3s ease;

      &::v-deep b{
        font-weight: 500;
        color: $color-primary-darker;
        background: $color-primary-lightest;
        border-radius: 3px;
      }

      &:hover{
        cursor: pointer;
        border-color: $color-primary-lighter;
        background: $color-primary-lightest;
      }
    }
  }
}
</style>
