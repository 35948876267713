var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "GeneratedStatement" }, [
    !_vm.hasEmbeddedValue
      ? _c("span", [_c("p", { domProps: { innerHTML: _vm._s(_vm.text) } })])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }