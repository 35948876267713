<template>
  <div class="GeneratedStatement">
    <!--  <p
      v-if="existText"
      class="text"
      v-html="text"
    /> -->

    <span v-if="!hasEmbeddedValue">
      <p v-html="text" />
    </span>
  </div>
</template>

<script>
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [i18nAttributeMixin],

  computed: {
    text() {
      return this.hasEmbeddedValue ? this.$t(this.i18nKey, [this.value.toLowerCase()]) : this.$t(this.$attrs.i18n);
    },

    existText() {
      return this.$te(this.$attrs.i18n);
    },

    hasEmbeddedValue() {
      const i18nText = this.$t(this.i18nKey);
      return /\{0\}/g.test(i18nText);
    },

    i18nKey() {
      return `STATEMENT.${this.answerTag}`;
    },

    subtype() {
      return this.$attrs?.subtype;
    },

    value() {
      const value = this.$attrs?.value;
      if (this.subtype === 'choicesCustom') {
        return (value?.subvalue) ? `${value.subvalue}` : this.$t(`QUESTION.${this.answerTag}.CHOICE.${value.value}`);
      }

      if (this.field && (typeof value === 'object')) return value[this.field];

      return value ?? '--';
    },

    // value() {
    //   const value = this.$attrs?.value;
    //   if (this.field && (typeof value === 'object')) return value[this.field];
    //   return this.$attrs.value ?? '--';
    // },

    field() {
      return this.$attrs?.field;
    },
  },
};
</script>

<style lang="scss" scoped>
.GeneratedStatement{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  gap: $spacing-2xs;
}
</style>
