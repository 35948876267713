var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseInputFile" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "label",
        { attrs: { for: _vm.name } },
        [
          _vm.isEmpty
            ? _c("span", [_vm._v(" " + _vm._s(_vm.placeholderText) + " ")])
            : _vm._l(_vm.files, function(file, i) {
                return _c("span", { key: i, staticClass: "file" }, [
                  _vm._v(" " + _vm._s(file) + " ")
                ])
              })
        ],
        2
      ),
      _c("input", {
        attrs: {
          id: _vm.name,
          type: "file",
          accept: _vm.accept,
          name: _vm.name,
          multiple: "multiple"
        },
        on: { change: _vm.updateFilesNamesList }
      })
    ]),
    _vm.placeholder
      ? _c("p", { staticClass: "placeholder" }, [
          _vm._v(" " + _vm._s(_vm.placeholder) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }