import Vue from 'vue';
import '@/config/axiosConfig';
import '@/config/baseComponentsImporter';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueTippy, { TippyComponent } from 'vue-tippy';

import ThemeMixin from '@/mixins/ThemeMixin';

import App from './App';
import router from './router';
import store from './store';
import i18n from './config/i18n';

import GLOBALSETTINGS from './constants';
import GlobalHelper from './globalHelper';

Vue.prototype.$settings = GLOBALSETTINGS;
Vue.use(GlobalHelper);

library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.mixin(ThemeMixin);

window.Vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

Vue.use(VueToast, {
  duration: 5000,
  position: 'top-right'
});

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

Vue.use(VueGeolocation);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDXpEiuKJVbYGkuwsVYRFmVGqHMSW7jdIU',
    libraries: 'places'
  }
});
