var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RadioChoicesCustom" },
    [
      _vm._l(_vm.choices, function(data, choice) {
        return _c(
          "div",
          { key: choice, staticClass: "choice-block" },
          [
            _c(
              "div",
              {
                staticClass: "choice",
                class: {
                  selected:
                    (_vm.innerValue && _vm.innerValue.value === choice) ||
                    _vm.hasSubchoiceSelected(choice)
                },
                on: {
                  click: function($event) {
                    return _vm.selectChoice($event, choice, data.subchoices)
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "QUESTION." +
                          (_vm.$attrs.i18n || _vm.$attrs.name) +
                          ".CHOICE." +
                          choice
                      )
                    ) +
                    " "
                ),
                data.subSubchoice
                  ? _c("div", { staticClass: "sub-subchoice-block" }, [
                      _c(
                        "div",
                        { staticClass: "sub-subchoice" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "QUESTION." +
                                    (_vm.$attrs.i18n || _vm.$attrs.name) +
                                    ".SUBCHOICE." +
                                    choice
                                )
                              )
                            )
                          ]),
                          _c(
                            _vm.subquestionType(data.subSubchoice),
                            _vm._b(
                              {
                                tag: "component",
                                staticClass: "subquestion",
                                on: {
                                  input: function($event) {
                                    _vm.answerSubquestion(
                                      "" + choice,
                                      $event && $event.target
                                        ? $event.target.value
                                        : $event
                                    )
                                  }
                                },
                                model: {
                                  value: data.answer,
                                  callback: function($$v) {
                                    _vm.$set(data, "answer", $$v)
                                  },
                                  expression: "data.answer"
                                }
                              },
                              "component",
                              _vm.subquestionAttributes(data.subSubchoice),
                              false
                            )
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._l(data.subchoices, function(subchoice, i) {
              return _c("div", { key: i, staticClass: "subchoice-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "subchoice",
                    class: {
                      selected:
                        _vm.innerValue &&
                        _vm.innerValue.value === choice + "." + subchoice.value
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectSubchoice(
                          $event,
                          choice + "." + subchoice.value
                        )
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "QUESTION." +
                                _vm.$attrs.name +
                                ".CHOICE." +
                                choice +
                                "-" +
                                subchoice.value
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "sub-subchoice" },
                      [
                        subchoice.subchoice
                          ? _c(
                              _vm.subquestionType(subchoice.subchoice),
                              _vm._b(
                                {
                                  tag: "component",
                                  staticClass: "subquestion",
                                  on: {
                                    input: function($event) {
                                      _vm.answerSubquestion(
                                        choice + "." + subchoice.value,
                                        $event && $event.target
                                          ? $event.target.value
                                          : $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: subchoice.answer,
                                    callback: function($$v) {
                                      _vm.$set(subchoice, "answer", $$v)
                                    },
                                    expression: "subchoice.answer"
                                  }
                                },
                                "component",
                                _vm.subquestionAttributes(
                                  choice +
                                    "-" +
                                    subchoice.value +
                                    "-" +
                                    (i + 1),
                                  subchoice.subchoice
                                ),
                                false
                              )
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ])
            })
          ],
          2
        )
      }),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }