var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SearcherSelect", attrs: { name: _vm.$attrs.name } },
    [
      _c("div", { staticClass: "select-content" }, [
        _c(
          "div",
          { staticClass: "searcher" },
          [
            _c(
              "BaseInput",
              _vm._b(
                {
                  model: {
                    value: _vm.input.value,
                    callback: function($$v) {
                      _vm.$set(_vm.input, "value", $$v)
                    },
                    expression: "input.value"
                  }
                },
                "BaseInput",
                _vm.input,
                false
              )
            ),
            _c(
              "span",
              {
                staticClass: "dropdown-button",
                class: { open: _vm.choicesVisibility },
                on: {
                  click: function($event) {
                    return _vm.toggleVisibility()
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.choicesVisibility
          ? _c(
              "ul",
              { staticClass: "options", class: ["position--" + _vm.position] },
              _vm._l(_vm.filteredChoices, function(choice) {
                return _c("li", {
                  key: choice.key,
                  staticClass: "option",
                  attrs: { value: choice.key },
                  domProps: { innerHTML: _vm._s(choice.value) },
                  on: {
                    click: function($event) {
                      return _vm.chooseOption(choice.key)
                    }
                  }
                })
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }