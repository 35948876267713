var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseText" },
    [
      _vm.$attrs.label
        ? _c("label", { attrs: { for: _vm.$attrs.id } }, [
            _vm._v(" " + _vm._s(_vm.$attrs.label) + " ")
          ])
        : _vm._e(),
      _c(
        "textarea",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            domProps: { value: _vm.innerValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.innerValue = $event.target.value
              }
            }
          },
          "textarea",
          _vm.inputAttributes,
          false
        )
      ),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }