<template>
  <transition
    :name="name"
    mode="out-in"
    :enter-active-class="`${name}-enter-active ${name}`"
    :leave-active-class="`${inverseName}-leave-active ${inverseName}`"
    appear
  >
    <slot />
  </transition>
</template>

<script>
const sides = ['left', 'right', 'top', 'bottom'];
export default {
  props: {
    side: {
      type: String,
      default: sides[0],
      validator: side => sides.includes(side),
    },
  },

  computed: {
    name() {
      return `slide-${this.side}`;
    },

    inverseName() {
      switch (this.side) {
        case 'left': return 'slide-right';
        case 'right': return 'slide-left';
        case 'top': return 'slide-bottom';
        case 'bottom': return 'slide-top';
        default: return `slide-${this.side}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-left {
  --local-translate-x: 3rem;
  --local-translate-y: 0rem;
}

.slide-right {
  --local-translate-x: -3rem;
  --local-translate-y: 0rem;
}

.slide-top {
  --local-translate-x: 0rem;
  --local-translate-y: 2rem;
}

.slide-bottom {
  --local-translate-x: 0rem;
  --local-translate-y: -2rem;
}

.slide-left,
.slide-right,
.slide-top,
.slide-bottom{
  &-enter-active,
  &-leave-active {
    transition: all 0.5s;
    overflow: hidden;
  }

  &-enter {
    opacity: 0;
    transform: translate(calc(var(--local-translate-x) * -1), calc(var(--local-translate-y) * -1));
  }

  &-leave-to {
    opacity: 0;
    transform: translate(var(--local-translate-x), var(--local-translate-y));
  }
}
</style>
