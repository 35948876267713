var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseSelect", attrs: { name: _vm.$attrs.name } },
    [
      _c("div", { staticClass: "select-content" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            staticClass: "form-input",
            class: { "has-selected-value": _vm.innerValue },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.innerValue = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              {
                staticClass: "default-option",
                attrs: { disabled: "" },
                domProps: { value: null }
              },
              [_vm._v(" " + _vm._s(_vm.$t("SELECT.PLACEHOLDER.COURSE")) + " ")]
            ),
            _vm._l(_vm.$attrs.choices, function(choice, i) {
              return _c("option", { key: i, domProps: { value: choice } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "QUESTION." + _vm.$attrs.name + ".CHOICE." + choice
                      )
                    ) +
                    " "
                )
              ])
            })
          ],
          2
        ),
        _c(
          "span",
          {
            staticClass: "dropdown-button",
            on: {
              click: function($event) {
                return _vm.$refs.select.focus()
              }
            }
          },
          [
            _c("font-awesome-icon", {
              staticClass: "arrow",
              attrs: { icon: ["fas", "chevron-right"] }
            })
          ],
          1
        )
      ]),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }