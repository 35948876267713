var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "IndicatorBasicInformation" },
    _vm._l(_vm.information, function(row, i) {
      return _c("li", { key: i, staticClass: "report-block" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("INFORMATION." + row.label)))]),
        _c("div", { domProps: { innerHTML: _vm._s(row.value) } })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }