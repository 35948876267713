<template>
  <div id="app">
    <TheHeader />

    <router-view class="view" />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/header/TheHeader';
import TheFooter from './components/common/TheFooter';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style lang="scss">
@import '~@/assets/styles/_reset.scss';
@import '~@/assets/styles/app.scss';

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;

  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-weight: 300;
  //font-family: $font-family-secondary;
  background: $color-neutral-lightest;
}

.view {
  flex: 1;
}

.window-fit{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.header-fit{
  padding-top: calc(#{$theHeaderSpacing} + #{$spacing-m}) !important; // menu + padding
}

</style>
