var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "RouteStatement" }, [
    _vm.$te(_vm.labeli18n)
      ? _c("p", { staticClass: "label" }, [
          _vm._v(" " + _vm._s(_vm.$t(_vm.labeli18n)) + " ")
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "buttons" },
      _vm._l(_vm.blocks, function(block, i) {
        return _c(
          "BaseButton",
          {
            key: i,
            attrs: { variation: _vm.buttonType(i) },
            on: {
              click: function($event) {
                return _vm.$emit("force-redirect", block)
              }
            }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("STATEMENT." + _vm.answerTag + ".CHOICE." + (i + 1))
                ) +
                " "
            )
          ]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }