var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TableCustom" },
    [
      _vm.hasDinamicallyColumns || _vm.hasDinamicallyRow
        ? _c("div", { staticClass: "action" }, [
            _vm.hasDinamicallyColumns
              ? _c(
                  "div",
                  { staticClass: "add-button" },
                  [
                    _c(
                      "BaseInput",
                      _vm._b(
                        {
                          model: {
                            value: _vm.newColumn.value,
                            callback: function($$v) {
                              _vm.$set(_vm.newColumn, "value", $$v)
                            },
                            expression: "newColumn.value"
                          }
                        },
                        "BaseInput",
                        _vm.newColumn,
                        false
                      )
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { size: "s" },
                        on: {
                          click: function($event) {
                            return _vm.addNewColumn(_vm.newColumn.value)
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "plus"] }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasDinamicallyRow
              ? _c(
                  "div",
                  { staticClass: "add-button" },
                  [
                    _c(
                      "BaseInput",
                      _vm._b(
                        {
                          model: {
                            value: _vm.newRow.value,
                            callback: function($$v) {
                              _vm.$set(_vm.newRow, "value", $$v)
                            },
                            expression: "newRow.value"
                          }
                        },
                        "BaseInput",
                        _vm.newRow,
                        false
                      )
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { size: "s" },
                        on: {
                          click: function($event) {
                            return _vm.addNewRow(_vm.newRow.value)
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "plus"] }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.contents
        ? _c("BaseTableCustom", {
            attrs: {
              headers: _vm.headers,
              body: _vm.contents,
              answer: _vm.value
            },
            on: { "set-value": _vm.setValue }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }