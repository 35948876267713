<template>
  <div class="MenuContentResponsive">
    <TransitionSlide>
      <ul class="panel">
        <li
          v-for="(item, i) in items"
          :key="i"
        >
          <router-link :to="`/${item.link}`">
            <p class="title">
              {{ item.name }}
            </p>

            <p class="subtitle">
              {{ item.description }}
            </p>
          </router-link>
        </li>
      </ul>
    </TransitionSlide>
  </div>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },

  mounted() {
    const headerHeight = document.querySelector('.TheHeader')?.offsetHeight;

    if (headerHeight) {
      this.$el.style.top = `${headerHeight}px`;
    }
  },
};
</script>

<style lang="scss" scoped>
.MenuContentResponsive {
  background-color: hsl(0deg 0% 0% / 40%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  .panel {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    box-shadow: 5px 5px 10px hsla(0, 0%, 0%, 0.15);
    background-color: $color-neutral-darkest;
    z-index: 10;

    padding-top: $spacing-l;
    li {
      a {
        display: block;
        color: white;
        text-decoration: none;
        padding: $spacing-s $spacing-l;
        margin: 0;
        transition: all 0.5s ease;

        p {
          margin: 0;
        }

        &.disabled {
          pointer-events: none;
          filter: grayscale(1) opacity(0.5);
        }

        &:hover {
          background-color: $color-primary;
        }

        .title {
          text-transform: uppercase;
        }

        .subtitle {
          font-size: $font-size-s;
          color: $color-neutral-mid;
        }
      }
    }
  }

  @media #{$breakpoint-sm-min} {
    .panel {
      right: 50%;
    }
  }
}
</style>
