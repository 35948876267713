var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RadioBoxes" },
    [
      _c(
        "div",
        {
          staticClass: "items",
          style: { gridTemplateColumns: _vm.gridColumns }
        },
        _vm._l(_vm.choices, function(choice, i) {
          return _c(
            "div",
            { key: i, staticClass: "content" },
            [
              _c(
                "div",
                {
                  staticClass: "choice",
                  class: { selected: _vm.innerValue === choice.value },
                  on: {
                    click: function($event) {
                      return _vm.selectChoice($event, choice.value)
                    }
                  }
                },
                [
                  _vm.getImage(i)
                    ? _c("div", {
                        staticClass: "image",
                        style: [
                          {
                            "background-image": "url(" + _vm.getImage(i) + ")"
                          },
                          { "background-size": _vm.getImagesTypesDefined(i) }
                        ]
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(choice.text) + " ")])
                ]
              ),
              _vm.$attrs.files
                ? _c(
                    "ModalStatement",
                    _vm._b(
                      {},
                      "ModalStatement",
                      {
                        name: _vm.getButtonKey(choice.value),
                        src: _vm.getFile(i)
                      },
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }