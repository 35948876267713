<template>
  <div class="BaseInput">
    <label
      v-if="$attrs.label"
      :for="$attrs.id"
    >
      {{ $attrs.label }}
    </label>
    <div
      class="input"
    >
      <div
        v-if="icon"
        class="icon"
      >
        <font-awesome-icon :icon="icon" />
      </div>

      <input
        v-model="innerValue"
        v-bind="inputAttributes"
      >

      <div
        v-if="$attrs.type === 'range'"
        id="value"
        class="value"
      >
        {{ innerValue }} {{ units }}
      </div>


      <span
        v-if="units && $attrs.type !== 'range'"
        class="units"
      >{{ units }}</span>
    </div>


    <slot name="error" />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: undefined,
    },
  },


  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        let value = newValue;
        if (this.$attrs.type === 'number') {
          value = (newValue === '') ? undefined : parseInt(newValue, 10);
        }

        this.$emit('input', value);
      },
    },

    icon() {
      return this.$attrs?.icon;
    },

    units() {
      return this.$attrs?.units;
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, { label: undefined, type: this.type, icon: undefined });
    },

    type() {
      return (!this.$attrs.type || this.$attrs.disabled) ? 'text' : this.$attrs.type;
    },
  },


};
</script>


<style lang="scss" scoped>
.BaseInput {
  display: flex;
  flex-direction: column;

  label {
    margin: $spacing-xs $spacing-2xs;
  }

  .input {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: $spacing-2xs;

    .icon {
      z-index: 3;
      color: $color-neutral-dark !important;
      position: absolute;
      top: 0;
      left: $spacing-l;
      bottom: 0;
      display: grid;
      place-content: center;

      & + input {
        padding-left: 3.5rem;
      }
    }

    .value{
      background: $color-primary-dark;;
      padding: $spacing-xs;
      color:#fff;
      font-weight: 500;
      border-radius: 10px;
      width: 5.5rem;
      text-align: center;
      box-shadow: $shadow-elevation-1;
    }

    .units{
      font-weight: 600;
      background: $color-primary-dark;
      color:#fff;
      padding: $spacing-xs;
      width: 4rem;
      text-align: center;
      box-shadow: $shadow-elevation-1;
      border-radius: 10px;
    }
  }

  input {
    font-size: $font-size-m;
    padding: $spacing-s $spacing-l;
    border: none;
    border-radius: 50px;
    max-width: 500px;
    width: 100%;
    background-color: $color-neutral-light;

    &:focus {
      border-color: $color-primary;
      outline: none;
    }

    &:-moz-focusring {
      text-shadow: 0 0 0 transparent;
    }

    &.invalid {
      border: solid 1px #{$color-error};
    }
  }

// Input range

input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: $color-primary;
  border-radius: 20px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $color-primary-darker;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background:  $color-primary-lighter;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2497E3;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #2497E3;
  border-radius: 25px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2497E3;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #2497E3;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #2497E3;
}
input[type=range]:focus::-ms-fill-upper {
  background: #2497E3;
}


}
</style>
