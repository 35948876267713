/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  blocks: undefined,
  answers: undefined,
  loading: false,
  forcedReloading: false,
});

const state = () => getDefaultState();

export const getters = {
  getForcedReloading: state => state.forcedReloading,

  isLoading: state => state.loading,

  getBlocks: state => () => state.blocks,

  getBlock: state => blockId => state.blocks.find(block => block.id === blockId),

  getAnswers: state => () => state.answers,

  getAnswerByQuestion: state => (questionId, questionKey) => {
    const answer = state.answers?.find(answer => parseInt(answer.questionId, 10) === parseInt(questionId, 10) || (answer.questionKey === questionKey));
    return answer;
  },

  getAnswerByQuestionKey: state => questionKey => state.answers?.find(answer => answer.questionKey === questionKey),

  getBlockQuestionsAnswer: (state, getters) => (blockId) => {
    const block = getters.getBlock(blockId);

    const questions = getters.getAvailableQuestions(block.questions);
    if (!block || !questions) return undefined;

    return questions.map((question) => {
      const answer = getters.getAnswerByQuestion(question.id, question.key);
      return Object.assign(question, { answer: answer?.answer });
    });
  },

  getAvailableQuestions: (state, getters) => questions => questions?.filter(question => getters.checkQuestionDependence(question.dependences)),

  checkQuestionDependence: (state, getters) => (dependences) => {
    if (!dependences) return true;

    return dependences.some(dependence => dependence.every((dependency) => {
      const currentAnswer = getters.getAnswerByQuestion(dependency?.questionId, dependency?.questionKey);

      if (!currentAnswer) return false;

      if (dependency?.answer !== undefined) {
        if (dependency?.field && (typeof currentAnswer.answer === 'object')) {
          return currentAnswer.answer[dependency.field].toString() === dependency.answer.toString();
        }

        if (Array.isArray(dependency.answer)) {
          return dependency.answer.every(dependency => currentAnswer.answer.includes(dependency.toString()));
        }

        return currentAnswer.answer.toString() === dependency.answer.toString();
      }

      if (dependency?.regexp) {
        const regexp = new RegExp(dependency.regexp, 'g');
        return regexp.test(currentAnswer.answer);
      }

      return true;
    }));
  },
};

export const mutations = {
  ...make.mutations(state),

  FORCED_RELOADING(state) {
    state.forcedReloading = !state.forcedReloading;
    console.log('FORCED_RELOADING');
  },

  SET_ANSWER(state, newAnswer) {
    const answers = state.answers.filter(answer => parseInt(answer.questionId, 10) !== parseInt(newAnswer.questionId, 10));
    state.answers = [...answers, newAnswer];
  },

  // COMPLETE_BLOCK(state, blockId) {
  //   const blocks = state.blocks?.map(block => ((block.id === blockId) ? Object.assign(block, { completed: true }) : block));
  //   state.blocks = blocks;
  // },
};

export const actions = {
  async fetchProfileQuestions({ commit }) {
    try {
      commit('SET_LOADING', true);

      console.log('fetchProfileQuestions');
      const { data, error } = await axios.get('/profile-questions');
      if (error) throw new Error(data);

      commit('SET_BLOCKS', data.profileBlocks);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchClientProfileQuestions({ commit }) {
    try {
      commit('SET_LOADING', true);

      console.log('fetchClientProfileQuestions');
      const { data, error } = await axios.get('/profile-client-questions');
      if (error) throw new Error(data);

      commit('SET_BLOCKS', data.profileBlocks);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAnswers({ commit }, userId) {
    try {
      commit('SET_LOADING', true);

      if (!userId) commit('SET_ANSWERS', []);
      else {
        console.log('fetchAnswers', { userId });
        const { data, error } = await axios.get(`/profile-answers/${userId}`);
        console.log(data);
        if (error) throw new Error(data);

        commit('SET_ANSWERS', data.answers || []);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendAnswers({ commit }, { answers, userId }) {
    try {
      commit('SET_LOADING', true);

      console.log('sendAnswers', { answers, userId });
      const { data, error } = await axios.post('/profile-answers', { answers, userId });
      if (error) throw new Error(data);

      // commit('COMPLETE_BLOCK', blockId);

      if (data?.answers) data.answers.forEach(answer => commit('SET_ANSWER', answer));
    } catch (e) {
      console.log(e);
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendClientAnswers({ commit }, { answers, clientId }) {
    try {
      commit('SET_LOADING', true);

      console.log('sendClientAnswers', { answers, clientId });
      // At server => if(userId === token) then createProfile, else check if is client first and add/modify client
      const { data, error } = await axios.post('/profile-client-answers', { answers, clientId });
      if (error) throw new Error(data);

      // commit('COMPLETE_BLOCK', blockId);

      if (data?.answers) data.answers.forEach(answer => commit('SET_ANSWER', answer));

      commit('FORCED_RELOADING');
    } catch (e) {
      console.log(e);
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },


  async saveAnswer({ commit }, answer) {
    commit('SET_ANSWER', answer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
