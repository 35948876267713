var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseTable" }, [
    _c("table", [
      _c(
        "thead",
        _vm._l(_vm.headers, function(head, i) {
          return _c(
            "tr",
            { key: i },
            _vm._l(head, function(column, j) {
              return _c(
                "th",
                {
                  key: j,
                  attrs: {
                    rowspan: column.row ? column.row : _vm.headers.length,
                    colspan: column.col ? column.col : 1
                  }
                },
                [_vm._v(" " + _vm._s(_vm.getHeadersName(column)) + " ")]
              )
            }),
            0
          )
        }),
        0
      ),
      _c(
        "tbody",
        [
          _vm._l(_vm.paginatedBody, function(row, i) {
            return _c(
              "tr",
              { key: i },
              [
                _vm._l(row, function(field, j) {
                  return _c("td", { key: j }, [
                    j !== "avatar"
                      ? _c("span", [_vm._v(_vm._s(field))])
                      : _vm._e(),
                    j === "avatar" && field !== ""
                      ? _c("p", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage:
                              "url(" + _vm.routeAvatar + field + ")"
                          }
                        })
                      : _vm._e(),
                    j === "avatar" && field === ""
                      ? _c("p", { staticClass: "avatar" })
                      : _vm._e()
                  ])
                }),
                _vm.areSlot("actions-" + i)
                  ? _c("td", [_vm._t("actions-" + i)], 2)
                  : _vm._e()
              ],
              2
            )
          }),
          _vm.paginatedBody.length === 0
            ? _c("tr", { staticClass: "empty-tr" }, [
                _c("td", { attrs: { colspan: _vm.headersLength } }, [
                  _vm._v(" No hay información ")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ]),
    _vm.maxValue
      ? _c(
          "div",
          { staticClass: "paginationContainer" },
          [
            _c(
              "span",
              {
                staticClass: "pagination-control prev",
                class: { disabled: _vm.pagination.current < 1 },
                on: {
                  click: function($event) {
                    return _vm.setPage(0)
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-left"] }
                }),
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-left"] }
                })
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "pagination-control prev",
                class: { disabled: _vm.pagination.current < 1 },
                on: {
                  click: function($event) {
                    return _vm.prevPage()
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-left"] }
                })
              ],
              1
            ),
            _vm._l(_vm.pages, function(page) {
              return _c(
                "span",
                {
                  key: "page" + page,
                  staticClass: "pagination-control page",
                  class: { active: page === _vm.pagination.current },
                  on: {
                    click: function($event) {
                      return _vm.setPage(page)
                    }
                  }
                },
                [_vm._v(_vm._s(page + 1))]
              )
            }),
            _c(
              "span",
              {
                staticClass: "pagination-control next",
                class: {
                  disabled: _vm.paginatedBody.length < _vm.pagination.size
                },
                on: { click: _vm.nextPage }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "pagination-control next",
                class: {
                  disabled: _vm.paginatedBody.length < _vm.pagination.size
                },
                on: {
                  click: function($event) {
                    return _vm.setPage(_vm.maxValue)
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-right"] }
                }),
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }