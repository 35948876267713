<template>
  <nav class="Menu">
    <div
      v-if="responsive"
      class="menu-bars"
      @click="toggleVisibility()"
    >
      <font-awesome-icon
        v-if="!opened"
        :icon="['fas', 'bars']"
      />
      <font-awesome-icon
        v-else
        :icon="['fas', 'times']"
      />
    </div>

    <MenuContent
      v-if="!responsive"
      :items="items"
    />

    <MenuContentResponsive
      v-else-if="responsive && opened"
      :items="items"
      @click.native="closeContentResponsive($event)"
    />
  </nav>
</template>

<script>

import MenuContent from '@/components/header/MenuContent';
import MenuContentResponsive from '@/components/header/MenuContentResponsive';

export default {
  name: 'Menu',
  components: {
    MenuContent,
    MenuContentResponsive,
  },

  data() {
    return {
      responsive: undefined,
      opened: false,
      items: [
        {
          link: 'panel-admin', name: this.$t('TITLE.STATS.ADMIN'), icon: ['fas', 'list-ul'], description: '',
        },
        {
          link: 'user-admin', name: this.$t('TITLE.STATS.USERS'), icon: ['fas', 'list-ul'], description: '',
        },
        {
          link: 'centers', name: this.$t('TITLE.STATS.CENTERS'), icon: ['fas', 'list-ul'], description: '',
        },
        {
          link: 'courses', name: this.$t('FILTER.NAME.COURSES'), icon: ['fas', 'list-ul'], description: '',
        },

      ],
    };
  },

  watch: {
    responsive(newValue) {
      if (!newValue) {
        this.opened = false;
      }
    },
  },

  async created() {
    window.addEventListener('resize', this.toggleMenu);
  },

  mounted() {
    this.toggleMenu();
  },

  updated() {
    this.toggleMenu();
  },

  methods: {
    toggleMenu() {
      const headerWidth = document.querySelector('.TheHeader')?.offsetWidth ?? 0;
      if (headerWidth > 0) {
        this.responsive = (headerWidth < 1300);
      } else {
        setTimeout(this.toggleMenu, 1000);
      }
    },

    toggleVisibility() {
      this.opened = !this.opened;
    },

    closeContentResponsive({ target }) {
      const isContentResponsiveLink = (target.closest('.MenuContentResponsive a') !== null);
      if (isContentResponsiveLink) {
        this.opened = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Menu {
  order: -1;
  a {
    text-decoration: none;
  }

  .menu-bars {
    display: inline-block;
    margin-top: 7px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }

  @media #{$breakpoint-xl-min} {
    order: 0;
  }
}
</style>
