<template>
  <div class="Map">
    <div
      v-show="optionsMapUbication"
      class="access"
    >
      <BaseButton
        class="button"
        variation="secondary"
        @click="allowAccess()"
      >
        <img
          class="image"
          :src="require(`@/assets/iconos/ubicacion.svg`)"
        > {{ $t('QUESTION.NA23.MY.UBICATION') }}
      </BaseButton>
    </div>

    <div v-show="optionsMapSearch">
      <label>
        <gmap-autocomplete
          id="autocomplete"
          class="inputComplete"
          :placeholder="$t('QUESTION.NA23.SITE.UBICATION')"
          @place_changed="setPlace"
        />
      </label>
    </div>

    <div
      v-if="myCoordinates"
      class="my-ubication"
    >
      <label for="Latitud">{{ $t('LATITUDE') }}</label> <BaseInput :value="myCoordinates.lat" />
      <label for="Longitud">{{ $t('LENGTH') }}</label> <BaseInput :value="myCoordinates.lng" />
    </div>

    <GmapMap
      ref="mapRef"
      :center="centerMap"
      :zoom="zoom"
      map-type-id="satellite"

      class="google-map"
    >
      <GmapMarker
        v-if="access"
        :position="myCoordinates"
        :clickable="true"
        :draggable="true"
        @click="center=myCoordinates"
        @drag="updateCoordinates"
      />
    </GmapMap>

    <slot name="error" />
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [ModelMixin, i18nAttributeMixin],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      map: null,
      myCoordinates: null,
      zoom: 7,
      access: 0,
      markers: [],
      permissionUbication: 0,
    };
  },

  computed: {

    centerMap() {
      if (!this.myCoordinates) {
        return {
          lat: 43.3530573,
          lng: -2.8405813,
        };
      }

      return {
        lat: this.myCoordinates.lat,
        lng: this.myCoordinates.lng,
      };
    },

    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
    },

    optionsMapUbication() {
      if (this.permissionUbication === 0 && this.$attrs?.type === 'ubication-search') {
        return true;
      }
      if (this.permissionUbication === 1 && this.$attrs?.type === 'ubication-search') {
        return true;
      }
      if (this.permissionUbication === 0 && this.$attrs?.type === 'ubication') {
        return true;
      }
      if (this.permissionUbication === 1 && this.$attrs?.type === 'ubication') {
        return true;
      }

      return false;
    },

    optionsMapSearch() {
      if (this.$attrs?.type === 'ubication-search' || this.$attrs?.type === 'search') {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => this.map === map);

    if (this.value !== null) {
      const position = {
        lat: parseFloat(this.value.lat),
        lng: parseFloat(this.value.lng),
      };

      this.myCoordinates = position;
      this.innerValue = this.myCoordinates;
      this.access = 1;

      console.log('value', this.value);
    }
  },

  methods: {
    allowAccess() {
      this.$getLocation({})
        .then((coordinates) => {
          this.myCoordinates = coordinates;
          this.zoom = 18;
          this.access = 1;
          this.innerValue = this.myCoordinates;
          this.permissionUbication = 1;
        }).catch(this.accessNotAllowed());
    },

    accessNotAllowed() {
      this.permissionUbication = 2;
    },

    setPlace(coordinates) {
      const myCoordinates = coordinates;
      this.access = true;

      const position = {
        lat: myCoordinates.geometry.location.lat(),
        lng: myCoordinates.geometry.location.lng(),
      };
      this.zoom = 18;
      this.myCoordinates = position;
      this.innerValue = this.myCoordinates;

      const autocomplete = document.getElementById('autocomplete');
      autocomplete.value = '';
    },

    updateCoordinates(location) {
      this.myCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },

  },


};
</script>

<style lang="scss" scoped>
.Map{
  .access{
    margin-bottom: $spacing-m;
    .button{
      width: 100%;
    }
    .image{
      width: 1.5rem;
    }
  }

   .inputComplete{
    width:100%;
    height: 2.5rem;
    border-radius: 5px;;
    border: solid 1px $color-primary;
    margin-bottom: $spacing-m;
    padding: $spacing-m;
  }

  .my-ubication{
    margin-bottom: $spacing-s;
    background-color: #fff;
    padding: $spacing-s;
  }

  .google-map{
    width: 100%;
    height: 350px;
  }
}

</style>
