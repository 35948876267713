<template>
  <div class="UploadFile">
    <div
      class="camera"
    >
      <font-awesome-icon
        :icon="['fas', 'camera']"
        @click="$refs.inputFile.click()"
      />
    </div>


    <input
      v-show="0"
      ref="inputFile"
      type="file"
      accept="image/*"
      @change="loadImage($event)"
    >

    <BaseInputFile
      class="buttonFile"
      tag="file"
      :accept="accept"
      @change.native="loadImage($event)"
    />

    <div
      v-if="typeFile === 'image'"
      class="preview"
      :style="{backgroundImage:'url('+currentImage+')'}"
    />

    <!--    <BaseButton>Guardar imagen Seleccionada </BaseButton> -->
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      file: null,
      preview: null,

    };
  },
  computed: {
    ...get('programModule', [
      'isLoading',
      'getUrlimage',

    ]),

    currentImage() {
      return this.imageUrl ? this.imageUrl?.route : this.value?.route;
    },

    nameImage() {
      return this.imageUrl ? this.imageUrl?.name : this.value?.name;
    },

    accept() {
      return this.$attrs?.type ? this.$attrs?.type : undefined;
    },

    typeFile() {
      return this.$attrs?.file ? this.$attrs?.file : undefined;
    },

    programId() {
      return this.$route?.params.programId;
    },

    reportId() {
      return this.$route?.params.reportId;
    },

    imageUrl() {
      return this.getUrlimage();
    },

  },

  methods: {
    clickInput() {
      return this.$refs.inputFile.click();
    },

    loadImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.preview = e.target.result;
        };

        this.file = input.files[0];

        // this.innerValue = this.file;

        // this.saveImage(this.file);
        reader.readAsDataURL(input.files[0]);
      }
      this.saveImage(this.file);
    },

    async  saveImage(file) {
      await this.$store.dispatch('programModule/sendImageUpload', { file });
      const result = this.getUrlimage();

      // Delete image anteriores
      /* if (this.value !== undefined) {
        const nameFile = this.nameImage;
        await this.$store.dispatch('programModule/deleteFileLast', { file: nameFile });
      }
 */
      const answers = [{
        answer: result,
        questionId: this.$attrs.questionId,
        questionKey: this.$attrs.name,
      }];

      await this.$store.dispatch('programModule/sendAnswers', {
        answers,
        blockId: 1,
        programId: this.programId,
        reportId: this.reportId,
      });
    },
  },


};
</script>

<style lang="scss" scoped>
  .UploadFile{
    .camera{
      font-size: 6rem;
      text-align: center;
      color: $color-neutral-mid-light;
      cursor: pointer;
    }
    .preview{
      position: relative;
      --local-image-size: 19rem;
      padding: var(--spacing-m);
      height: var(--local-image-size);
      width: var(--local-image-size);
      border-radius: 5px;
      background-color: var(--color-neutral-mid-light);
      margin-bottom: var(--spacing-xs);
      background-size: cover;
      margin:auto;
    }

    .buttonFile{
      margin-top: $spacing-m;
    }
  }
</style>
