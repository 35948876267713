var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "footer window-fit" }, [
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.euskadi.eus/gobierno-vasco/educacion-ambiental/",
                target: "blank"
              }
            },
            [
              _c("img", {
                staticClass: "ih",
                attrs: { src: require("@/assets/images/logo_geemis.jpg") }
              })
            ]
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.euskadi.eus/inicio/",
                target: "blank"
              }
            },
            [
              _c("img", {
                staticClass: "fondo",
                attrs: { src: require("@/assets/images/gv-vasco-ambiente.png") }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }