<template>
  <div class="RadioChoicesCustom">
    <div
      v-for="(data, choice) in choices"
      :key="choice"
      class="choice-block"
    >
      <div
        class="choice"
        :class="{ 'selected': innerValue && (innerValue.value === choice) || hasSubchoiceSelected(choice)}"
        @click="selectChoice($event, choice, data.subchoices)"
      >
        {{ $t(`QUESTION.${$attrs.i18n || $attrs.name}.CHOICE.${choice}`) }}

        <div
          v-if="data.subSubchoice"
          class="sub-subchoice-block"
        >
          <div class="sub-subchoice">
            <label>{{ $t(`QUESTION.${$attrs.i18n || $attrs.name}.SUBCHOICE.${choice}`) }}</label>

            <component
              :is="subquestionType(data.subSubchoice)"
              v-model="data.answer"
              class="subquestion"
              v-bind="subquestionAttributes(data.subSubchoice)"
              @input="answerSubquestion(`${choice}`, ($event && $event.target) ? $event.target.value : $event)"
            />
          </div>
        </div>
      </div>

      <div
        v-for="(subchoice, i) in data.subchoices"
        :key="i"
        class="subchoice-block"
      >
        <div
          class="subchoice"
          :class="{ 'selected': innerValue && (innerValue.value === `${choice}.${subchoice.value}`) }"
          @click="selectSubchoice($event, `${choice}.${subchoice.value}`)"
        >
          <p>
            {{ $t(`QUESTION.${$attrs.name}.CHOICE.${choice}-${subchoice.value}`) }}
          </p>

          <div class="sub-subchoice">
            <!--  <label>{{ $t(`QUESTION.${$attrs.name}.CHOICE.${choice}-${subchoice.value}-${i+1}.LABEL`) }}</label> -->

            <component
              :is="subquestionType(subchoice.subchoice)"
              v-if="subchoice.subchoice"
              v-model="subchoice.answer"
              class="subquestion"
              v-bind="subquestionAttributes(`${choice}-${subchoice.value}-${i+1}`, subchoice.subchoice)"
              @input="answerSubquestion(`${choice}.${subchoice.value}`, ($event && $event.target) ? $event.target.value : $event)"
            />
          </div>
        </div>
      </div>
    </div>

    <slot name="error" />
  </div>
</template>

<script>

const QUESTIONS_TYPES = {
  input: 'BaseInput',
  number: 'BaseInput',
  select: 'BaseSelect',
};


export default {
  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  data() {
    return {
      choices: undefined,
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.removeOtherSubAnswers(newValue);
        this.$emit('input', newValue);
      },
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, { label: undefined });
    },
  },

  created() {
    this.choices = this.format(this.$attrs.choices);
  },

  methods: {
    hasSubchoiceSelected(choice) {
      if (!this.innerValue) return false;
      return new RegExp(`^${choice}{1}\\.`, 'g').test(this.innerValue.value);
    },

    selectChoice({ target }, value, subchoices) {
      const choice = target.closest('.choice');
      if (!choice.classList.contains('selected')) {
        this.clearSubchoices();
        this.clearChoices();

        choice.classList.add('selected');

        let newValue;
        if (subchoices.length) {
          choice.closest('.choice-block').querySelector('.subchoice').classList.add('selected');
          newValue = `${value}.${subchoices[0].value}`;
        } else {
          newValue = value;
        }

        this.innerValue = this.hasSubSubchoice(value) ? { value: newValue, subvalue: undefined } : { value: newValue };
      }
    },

    selectSubchoice({ target }, value) {
      const subchoice = target.closest('.subchoice');
      if (!subchoice.classList.contains('selected')) {
        this.clearSubchoices();
        this.clearChoices();

        subchoice.classList.add('selected');
        subchoice.closest('.choice-block').querySelector('.choice').classList.add('selected');

        if (this.hasSubSubchoice(value)) {
          const subvalue = (this.innerValue?.value === value) ? this.innerValue?.subvalue : undefined;
          this.innerValue = { value, subvalue };
        } else {
          this.innerValue = { value };
        }
      }
    },

    clearChoices() {
      const selectedChoice = this.$el.querySelectorAll('.choice.selected');
      if (selectedChoice) {
        Array.from(selectedChoice).map(choice => choice.classList.remove('selected'));
      }
    },

    clearSubchoices() {
      const selectedSubchoice = this.$el.querySelectorAll('.subchoice.selected');
      if (selectedSubchoice) {
        Array.from(selectedSubchoice).map(subchoice => subchoice.classList.remove('selected'));
      }
    },

    hasSubSubchoice(value) {
      const values = value.split('.');
      const choice = values[0];

      if (values.length > 1) {
        const currentSubchoice = values[1];
        const subchoices = this.choices[choice]?.subchoices;
        return (subchoices?.find(subchoice => subchoice.value === currentSubchoice)?.subchoice !== undefined);
      }
      return (this.choices[choice]?.subSubchoice !== undefined);
    },

    format(items) {
      return items.reduce((result, el) => {
        const values = el.value.toString().split('.');
        const choiceName = values[0];
        const accumulator = result;
        const answer = (el.value.toString() === this.value?.value) ? this.value?.subvalue : undefined;
        if (!accumulator[choiceName]) accumulator[choiceName] = { subSubchoice: el?.subchoice, answer, subchoices: [] };

        if (values[1]) {
          accumulator[choiceName].subchoices.push({ value: values[1], subchoice: el?.subchoice, answer });
        }

        return accumulator;
      }, Object.create(null));
    },

    isSubChoice(choice) {
      return (/\./g.test(choice));
    },

    subquestionType(subquestion) {
      return (typeof QUESTIONS_TYPES[subquestion?.type] !== 'undefined') ?
        QUESTIONS_TYPES[subquestion.type] :
        QUESTIONS_TYPES.input;
    },

    subquestionAttributes(name, subquestion) {
      if (subquestion?.type === 'number') {
        return Object.assign({}, subquestion, { type: 'number', name });
      }
      return Object.assign({}, subquestion, { type: undefined, name });
    },

    answerSubquestion(subquestionId, answer) {
      this.innerValue = { value: subquestionId, subvalue: answer };
    },

    removeOtherSubAnswers(newAnswer) {
      const keys = Object.keys(this.choices);
      for (let i = 0; i < keys.length; i += 1) {
        const currentKey = keys[i];
        this.choices[currentKey].answer = (currentKey === newAnswer.value) ? this.choices[currentKey].answer : undefined;
        this.choices[currentKey].subchoices.map((choice) => {
          const answer = (`${currentKey}.${choice.value}` === newAnswer.value) ? choice.answer : undefined;
          return Object.assign(choice, { answer });
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.RadioChoicesCustom {
  display: grid;
  gap: $spacing-2xs;

  .choice-block {
    .choice,
    .subchoice{
      padding: $spacing-s $spacing-xs;
      border-radius: 5px;
      // border-bottom: 1px solid $color-neutral-mid;
      transition: all 250ms ease;

      &:hover{
        cursor: pointer;
        background: $color-primary-lightest;
      }

      &:before {
        content: "";
        background: #fff;
        border-radius: 100%;
        border: 1px solid $color-neutral-mid-dark;
        display: inline-block;
        width: 1rem;
        height: 1rem;

        cursor: pointer;
        text-align: center;
        place-self: center center;

        transition: all 250ms ease;
      }

      &.selected{
        background: $color-primary-lighter;
        box-shadow: inset 0 0 0 2px $color-primary;

        &:before {
          background-color: $color-primary;
          box-shadow: inset 0 0 0 4px #fff;
        }
      }
    }

    .choice{
      align-items: center;
      gap: $spacing-xs;
      display: grid;
      grid-template-columns: 30px repeat(2, 1fr);
      // background: #fff;
      // box-shadow: $shadow-elevation-1;


      .sub-subchoice-block{
        grid-column: 2/4;

        .sub-subchoice{
          display: flex;
          gap: $spacing-xs;
          align-items: center;

          label{
            font-weight: 500;

            + * {
              flex: 1;
            }
          }
        }
      }
    }

    .subchoice{
      margin-left: $spacing-m;
    }

    .subchoice{
      background: $color-neutral-lighter;
      display: grid;
      grid-template-columns: 30px repeat(2, 1fr);


      p{
        grid-column: 2/4;
      }

      .sub-subchoice{
        display: flex;
        grid-column: 2/4;
        gap: $spacing-xs;

        label{
          font-weight: 500;
          + * {
            flex: 1;
          }
        }
      }
    }
  }


}
</style>
