<template>
  <div class="BaseSwitcher">
    <input
      :id="name"
      :name="name"
      type="checkbox"
      :checked="isChecked"
    >

    <label
      :for="name"
      @click="toggleOption()"
    />

    <span class="negative">{{ negative }}</span>
    <span class="positive">{{ positive }}</span>
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [ModelMixin, i18nAttributeMixin],

  props: {
    tag: {
      type: String,
      default: 'asd',
    },
  },

  computed: {
    name() {
      return `form-${this.tag}`;
    },

    positive() {
      return this.getOption(0);
    },

    negative() {
      return this.getOption(1);
    },

    isChecked() {
      return this.innerValue === true;
    },
  },

  methods: {
    toggleOption() {
      setInterval(() => { this.innerValue = !this.innerValue; }, 300); // animation time
    },

    getOption(option) {
      try {
        const optionValue = this.$attrs?.options[option];
        return this.getChoice(optionValue) ?? '';
      } catch (error) {
        return '';
      }
    },

    getChoice(choice) {
      return this.$t(`QUESTION.${this.answerTag}.CHOICE.${choice}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseSwitcher{
  display: flex;
  align-items: center;
  order: 2;

  span{
    text-transform: uppercase;
    font-size: $font-size-xs;
  }

  span.negative{
    order: 1;
    margin-right: $spacing-xs;
  }

  span.positive{
    order: 3;
    margin-left: $spacing-xs;
  }


  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    order: 2;
  }

  label {
    order: 2;
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 26px;
    background: $color-neutral-mid-dark;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0;
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: $color-primary;
  }

  input:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
  }

  input:checked + label + .negative + .positive{
    color: $color-primary;
    font-weight: 500;
  }

  input:not(:checked) + label + .negative{
    color: #{$color-error};
    font-weight: 500;
  }

  label:active:after {
    width: 33px;
  }
}
</style>
