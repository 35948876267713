var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading"
        }
      ],
      staticClass: "TheHeader",
      class: { "change-color": _vm.scrollPosition > 50 }
    },
    [
      _c(
        "div",
        { staticClass: "menu" },
        [
          _vm.isAuthenticated && _vm.user && _vm.user.profile === 1
            ? _c("Menu", { attrs: { user: _vm.user } })
            : _vm._e(),
          _vm.isAuthenticated
            ? _c("MenuTooltip", { attrs: { user: _vm.user } })
            : _vm._e(),
          _vm.isAuthenticated
            ? _c("ChangeLanguage", { attrs: { user: _vm.user } })
            : _c("Language", { staticClass: "language" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }