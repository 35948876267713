<template>
  <div class="BaseText">
    <label
      v-if="$attrs.label"
      :for="$attrs.id"
    >
      {{ $attrs.label }}
    </label>

    <textarea
      v-model="innerValue"
      v-bind="inputAttributes"
    />

    <slot name="error" />
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';

const MIN_ROWS = 5;

export default {
  mixins: [ModelMixin],

  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  computed: {
    rows() {
      return this.$attrs?.rows ?? MIN_ROWS;
    },

    placeholder() {
      const i18nText = this.$attrs?.placeholder;
      return i18nText ? this.$t(i18nText) : this.$t('TEXT.PLACEHOLDER');
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, { label: undefined, rows: this.rows, placeholder: this.placeholder });
    },
  },
};
</script>


<style lang="scss" scoped>
.BaseText {
  display: grid;

  label {
    margin: $spacing-xs $spacing-2xs;
  }

  textarea {
    font-size: $font-size-m;
    padding: $spacing-m $spacing-l;
    border: none;
    border-radius: 10px;
    width: 100%;
    background-color: $color-neutral-light;

    &:focus {
      border-color: $color-primary;
      outline: none;
    }

    &:-moz-focusring {
      text-shadow: 0 0 0 transparent;
    }

    &.invalid {
      border: solid 1px #{$color-error};
    }
  }
}
</style>
