/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';

const DEFAULT_LOCALE = 'es';

const getUserLocale = () => {
  const userData = localStorage.getItem('user-data');
  if (!userData) return DEFAULT_LOCALE;

  return JSON.parse(userData).locale || DEFAULT_LOCALE;
};

const getDefaultState = () => ({
  loading: false,
  locale: getUserLocale(),
  locales: undefined,
});

const state = () => getDefaultState();

export const getters = {
  getLocale: state => state.locale,

  getLocales: state => () => state.locales,

  getLocaleByLanguage: state => (language) => {
    if (!language || !state.locales) return undefined;

    const current = Object.entries(state.locales).find(locale => locale[0] === language);
    return current ? current[1] : undefined;
  },

  getLanguage: state => (currentLocale) => {
    if (!currentLocale || !state.locales) return undefined;

    return state.locales[currentLocale];
  },
};

const mutations = {
  ...make.mutations(state),
};

export const actions = {
  async fetchLocales({ commit }) {
    try {
      commit('SET_LOADING', true);
      const data = {
        es: 'Spanish',
        eu: 'Euskera',
        en: 'English',
      };
      commit('SET_LOCALES', data);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeLocale({ commit }, locale) {
    try {
      window.Vue.$i18n.locale = locale;
      commit('SET_LOCALE', locale);
    } catch (e) {
      this.$i18n.locale = DEFAULT_LOCALE;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
