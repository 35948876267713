var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInput" },
    [
      _vm.$attrs.label
        ? _c("label", { attrs: { for: _vm.$attrs.id } }, [
            _vm._v(" " + _vm._s(_vm.$attrs.label) + " ")
          ])
        : _vm._e(),
      _c("div", { staticClass: "input" }, [
        _vm.icon
          ? _c(
              "div",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: _vm.icon } })],
              1
            )
          : _vm._e(),
        _vm.inputAttributes.type === "checkbox"
          ? _c(
              "input",
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.innerValue,
                      expression: "innerValue"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.innerValue)
                      ? _vm._i(_vm.innerValue, null) > -1
                      : _vm.innerValue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.innerValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.innerValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.innerValue = $$c
                      }
                    }
                  }
                },
                "input",
                _vm.inputAttributes,
                false
              )
            )
          : _vm.inputAttributes.type === "radio"
          ? _c(
              "input",
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.innerValue,
                      expression: "innerValue"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: { checked: _vm._q(_vm.innerValue, null) },
                  on: {
                    change: function($event) {
                      _vm.innerValue = null
                    }
                  }
                },
                "input",
                _vm.inputAttributes,
                false
              )
            )
          : _c(
              "input",
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.innerValue,
                      expression: "innerValue"
                    }
                  ],
                  attrs: { type: _vm.inputAttributes.type },
                  domProps: { value: _vm.innerValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.innerValue = $event.target.value
                    }
                  }
                },
                "input",
                _vm.inputAttributes,
                false
              )
            ),
        _vm.$attrs.type === "range"
          ? _c("div", { staticClass: "value", attrs: { id: "value" } }, [
              _vm._v(
                " " + _vm._s(_vm.innerValue) + " " + _vm._s(_vm.units) + " "
              )
            ])
          : _vm._e(),
        _vm.units && _vm.$attrs.type !== "range"
          ? _c("span", { staticClass: "units" }, [_vm._v(_vm._s(_vm.units))])
          : _vm._e()
      ]),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }