var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "MenuTooltip",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.toogleActive($event)
        }
      }
    },
    [
      _vm.user && _vm.user.avatar != ""
        ? _c("div", {
            staticClass: "avatar",
            style: { backgroundImage: "url(" + _vm.avatar + ")" }
          })
        : _c(
            "div",
            { staticClass: "profile" },
            [
              _c("font-awesome-icon", {
                attrs: { size: "lg", icon: ["fas", "user"] }
              })
            ],
            1
          ),
      _c(
        "p",
        { staticClass: "caret-down" },
        [
          _c("font-awesome-icon", {
            attrs: { size: "lg", icon: ["fas", "caret-down"] }
          })
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "content-sections" },
          [
            _vm.user
              ? _c("MenuTooltipBody", { attrs: { user: _vm.user } })
              : _vm._e(),
            _c("MenuTooltipFooter", { attrs: { user: _vm.user } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }