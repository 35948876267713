/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  blocks: undefined,
  answers: undefined,
  loading: false,
  questions: undefined,
  program: undefined,
  urlimage: undefined,
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,

  getBlocks: state => () => state.blocks,

  getBlock: state => blockId => state.blocks.find(block => block.id === blockId),

  getAnswerByQuestion: state => (questionId, questionKey) => {
    const answer = state.answers?.find(answer => parseInt(answer.questionId, 10) === parseInt(questionId, 10) || (answer.questionKey === questionKey));
    return answer;
  },

  getAnswerByQuestionKey: state => questionKey => state.answers?.find(answer => answer.questionKey === questionKey),

  getBlockQuestionsAnswer: (state, getters) => (blockId) => {
    const block = getters.getBlock(blockId);

    const questions = getters.getAvailableQuestions(block.questions);
    if (!block || !questions) return undefined;

    return questions.map((question) => {
      const answer = getters.getAnswerByQuestion(question.id, question.key);
      return Object.assign(question, { answer: answer?.answer });
    });
  },

  getAvailableQuestions: (state, getters) => questions => questions?.filter(question => getters.checkQuestionDependence(question.dependences)),

  checkQuestionDependence: (state, getters) => (dependences) => {
    if (!dependences) return true;

    return dependences.some(dependence => dependence.every((dependency) => {
      const currentAnswer = getters.getAnswerByQuestion(dependency?.questionId, dependency?.questionKey);

      if (!currentAnswer) return false;

      if (dependency?.answer !== undefined) {
        if (dependency?.field && (typeof currentAnswer.answer === 'object')) {
          return currentAnswer.answer[dependency.field].toString() === dependency.answer.toString();
        }

        if (Array.isArray(dependency.answer)) {
          return dependency.answer.every(dependency => currentAnswer.answer.includes(dependency.toString()));
        }

        return currentAnswer.answer.toString() === dependency.answer.toString();
      }

      if (dependency?.regexp) {
        const regexp = new RegExp(dependency.regexp, 'g');
        return regexp.test(currentAnswer.answer);
      }

      return true;
    }));
  },

  getProgram: state => () => state.program,

  getUrlimage: state => () => state.urlimage,

  getAnswers: state => () => state.answers,

};

export const mutations = {
  ...make.mutations(state),

  SET_ANSWER(state, newAnswer) {
    const answers = state.answers.filter(answer => answer.questionId !== newAnswer.questionId);
    state.answers = [...answers, newAnswer];
  },

  COMPLETE_BLOCK(state, blockId) {
    const blocks = state.blocks?.map(block => ((block.id === blockId) ? Object.assign(block, { completed: true }) : block));
    state.blocks = blocks;
  },
};

export const actions = {
  async fetchProgram({ commit }, programId) {
    try {
      commit('SET_LOADING', true);
      const { data, error } = await axios.get(`/program/${programId}`);
      if (error) throw new Error(data);
      commit('SET_BLOCKS', data.blocks);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addProgram({ commit }, { programId, nameProgram }) {
    try {
      commit('SET_LOADING', true);


      const { data, error } = await axios.post('/add-program', { programId, nameProgram });
      if (error) throw new Error(data);
      commit('SET_PROGRAM', data || []);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateProgram({ commit }, { reportId }) {
    try {
      commit('SET_LOADING', true);

      console.log('Program', reportId);

      const { data, error } = await axios.post('/update-program', { reportId });
      if (error) throw new Error(data);

      commit('SET_PROGRAM', data || []);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAnswers({ commit }, reportId) {
    try {
      commit('SET_LOADING', true);
      const { data, error } = await axios.get(`/program-answers/${reportId}`);

      if (error) throw new Error(data);
      commit('SET_ANSWERS', data.answers || []);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendAnswers({ commit }, {
    answers, blockId, programId, reportId,
  }) {
    try {
      commit('SET_LOADING', true);

      console.log({
        answers, blockId, programId, reportId,
      });
      const { data, error } = await axios.post('/report_answers', {
        answers, blockId, programId, reportId,
      });
      if (error) throw new Error(data);

      commit('COMPLETE_BLOCK', blockId);

      if (data?.answers) data.answers.forEach(answer => commit('SET_ANSWER', answer));
    } catch (e) {
      console.log(e);
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendImageUpload({ commit }, {
    file,
  }) {
    try {
      commit('SET_LOADING', true);

      console.log('file', file);
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('file', file);

      const url = '/upload-image-program';

      const { data, error } = await axios.post(url, formData, options);
      if (error) throw new Error(data);

      console.log('save image', data);
      commit('SET_URLIMAGE', data);
    } catch (e) {
      console.log(e);
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteFileLast({ commit }, {
    file,
  }) {
    try {
      commit('SET_LOADING', true);

      console.log('Eliminando archivo', file);

      const { error } = await axios.post('delete-file', { file });
      if (error) throw new Error();
    } catch (e) {
      console.log(e);
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },


  async saveAnswer({ commit }, answer) {
    commit('SET_ANSWER', answer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
