<template>
  <ul class="IndicatorBasicInformation">
    <li
      v-for="(row, i) in information"
      :key="i"
      class="report-block"
    >
      <div>{{ $t(`INFORMATION.${row.label}`) }}</div>
      <div v-html="row.value" />
    </li>
  </ul>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  computed: {
    ...get('indicatorModule', [
      'getReportBlock',
      'getTopic',
      'getIndicator',
    ]),

    reportBlock() {
      return this.getReportBlock();
    },

    topic() {
      return this.getTopic();
    },

    indicator() {
      return this.getIndicator();
    },

    information() {
      const { code } = this.indicator;
      const type = (this.indicator.type === 1) ? 'INDICATOR.TYPE.MAIN' : 'INDICATOR.TYPE.ADDITIONAL';
      return [
        { label: 'BLOCK', value: this.$t(`REPORT_BLOCK.${this.reportBlock.id}.TITLE`) },
        { label: 'TOPIC', value: this.$t(`TOPIC.${this.topic.id}.TITLE`) },
        { label: 'INDICATOR_DESCRIPTION', value: this.$t(`INDICATOR.${code}.DESCRIPTION`) },
        { label: 'INDICATOR_TYPE', value: this.$t(type) },
        { label: 'INDICATOR_DEFINITION', value: this.$t(`INDICATOR.${code}.DEFINITION`) },
        { label: 'INDICATOR_REFERENCE', value: this.$t(`INDICATOR.${code}.REFERENCE`) },
        { label: 'INDICATOR_CLARIFICATIONS', value: this.$t(`INDICATOR.${code}.CLARIFICATIONS`) },
        { label: 'INDICATOR_PRACTICES', value: this.$t(`INDICATOR.${code}.PRACTICE`) },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.IndicatorBasicInformation{
  & > li{
    list-style: none;
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-auto-flow: column;
    margin-bottom: 0!important;

    &:nth-child(even){
      background: $color-primary-lightest;
    }
    &:nth-child(odd){
      background: #fff;
    }

    & > div:first-child{
      display: grid;
      align-items: center;
      font-weight: 500;
      // text-transform: uppercase;
    }

    div{
      overflow: auto;
      padding: $spacing-s;
    }
  }

}
</style>
