var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RadioChoices", style: { display: _vm.direction } },
    [
      _vm._l(_vm.choices, function(subchoices, choice) {
        return _c(
          "div",
          { key: choice, staticClass: "choice-block" },
          [
            _vm.getImage(choice - 1)
              ? _c(
                  "div",
                  {
                    staticClass: "choice-images",
                    class: {
                      selected:
                        _vm.innerValue === choice ||
                        _vm.hasSubchoiceSelected(choice)
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectChoiceImage($event, choice, subchoices)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: _vm.getImage(choice - 1), alt: "logo" }
                    }),
                    choice < _vm.$attrs.choices.length
                      ? _c("div", {
                          staticClass: "arrow",
                          style: {
                            "background-color":
                              "hsl(" +
                              choice +
                              "90deg, " +
                              choice +
                              "6%,  " +
                              choice +
                              "8%)"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.getChoice(choice)) + " ")
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "choice",
                    class: {
                      selected:
                        _vm.innerValue === choice ||
                        _vm.hasSubchoiceSelected(choice)
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectChoice($event, choice, subchoices)
                      }
                    }
                  },
                  [
                    _vm.range && choice < _vm.$attrs.choices.length
                      ? _c("div", {
                          staticClass: "arrow",
                          style: {
                            "background-color":
                              "hsl(" + choice + "99deg, 99%, 48%)"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.getChoice(choice)) + " ")
                  ]
                ),
            _vm._l(subchoices, function(subchoice, i) {
              return _c("div", { key: i, staticClass: "subchoice-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "subchoice",
                    class: {
                      selected: _vm.innerValue === choice + "." + subchoice
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectSubchoice(
                          $event,
                          choice + "." + subchoice
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "QUESTION." +
                              _vm.$attrs.name +
                              ".CHOICE." +
                              choice +
                              "-" +
                              subchoice
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            })
          ],
          2
        )
      }),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }