module.exports = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.slugify = text => text.replace(/\.|,/gi, '')
      .replace(/[&\\/\\#,+()$~%.'":*?<>{}]/g, '')
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .replace(/(\s+)/gi, '-')
      .toLowerCase();
  },
};
