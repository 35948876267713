<template>
  <div class="MenuTooltipBody">
    <div
      v-if="user && user.avatar != ''"
      class="profile"
      :style="{backgroundImage:'url('+avatar+')'}"
    />

    <div
      v-else
      class="profile"
    >
      <font-awesome-icon
        size="2x"
        :icon="['fas', 'user']"
      />
    </div>

    <div>
      <p class="name">
        {{ name }}

        <span class="type">{{ type }}</span>
      </p>

      <p class="email">
        {{ email }}
      </p>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...get('messageModule', [
      'getMailsReceivedNotRead',
    ]),

    name() {
      return this.user.name ?? '--';
    },

    email() {
      return this.user.email ?? '--';
    },

    type() {
      const type = this.user?.profile?.TYPE;
      if (!type) return undefined;

      return this.$t(`QUESTION.TYPE.CHOICE.${type}`);
    },

    avatar() {
      return `uploads/users/avatar/${this.user?.avatar}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuTooltipBody{
  display: flex;
  align-items: center;
  padding: $spacing-m $spacing-l;
  flex-flow: wrap;
  justify-content: center;
  gap: $spacing-m;

  .profile{
    --local-image-size: 50px;
    flex: 0 0 var(--local-image-size);
    height: var(--local-image-size);
    width: var(--local-image-size);
    border-radius: 50px;
    background-size: cover;

    & + div{
      flex: 1;
    }
  }

  p {
    margin-bottom: $spacing-2xs;
  }

  .name{
    font-size: $font-size-l;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .type{
    text-transform: uppercase;
    font-size: $font-size-xs;
    color: $color-neutral-mid-dark;
    background: $color-neutral-lightest;
    padding: $spacing-2xs;
  }

  @media #{$breakpoint-sm-max} {
    padding: $spacing-m;

    .profile{
      --local-image-size: 50px;

    }
  }
}
</style>
