var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ValidationButton" },
    [
      !_vm.isLastAttempts(_vm.currentAnswer)
        ? _c(
            "BaseButton",
            { attrs: { variation: "secondary" }, on: { click: _vm.check } },
            [_vm._v(" Comprobar ")]
          )
        : _vm._e(),
      _vm._t("error"),
      _vm.isLastAttempts(_vm.currentAnswer)
        ? _c("div", {
            staticClass: "fail-message",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("MESSAGE.VALIDATION." + _vm.answerTag + ".LASTFAIL")
              )
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }