<template>
  <div class="ChangeLanguage">
    <select
      v-model="locale"
      @change="changeLanguage($event)"
    >
      <option
        v-for="(language, code) in languages"
        :key="code"
      >
        {{ code }}
      </option>
    </select>
  </div>
</template>

<script>

export default {

  props: {
    user:
    {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      languages: {
        es: this.$t('LANGUAGE.SPANISH'),
        eu: this.$t('LANGUAGE.EUSKERA'),
        en: this.$t('LANGUAGE.ENGLISH'),
      },
      locale: 'eu',
    };
  },


  methods: {
    async changeLanguage(event) {
      const code = event.target.value;
      this.locale = code;
      await this.$store.dispatch('localeModule/changeLocale', code);
    },
  },

};
</script>

<style lang="scss" scoped>
.ChangeLanguage {
  margin: 0rem;

  .title {
    font-size: 1.6rem;
    padding: 1rem 2rem 1rem;
  }

  ul.language {
    display: flex;
    justify-content: center;
    padding: 1rem;
    li {
      a {
        text-decoration: none;
        color: rgb(245, 239, 239);
        text-transform: uppercase;
        &.active {
          color: $color-primary;
          font-weight: 600;
        }
      }
      &:first-child a {
        border-right: solid 1px rgb(247, 244, 244);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }

  select {
    margin: auto;
    margin-top: 0.8rem;
    background-color: none;
    border: none;
    background: transparent;
    color: #329bcc;
    font-size: 1.3rem;
    font-weight: bold;

    option{
      color: #fff;
      background: rgba(0, 0, 0, 0.8);
      border : none;
    }

    &:hover{
      cursor: pointer;
    }
  }
}
</style>
