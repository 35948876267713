<template>
  <div class="BoxesStatement">
    <p
      v-if="$te(labeli18n)"
      class="label"
      v-html="$t(labeli18n)"
    />

    <div
      class="boxes"
      :class="[choicesType]"
    >
      <div
        v-for="(choice, i) in choices"
        :key="i"
        class="box"
      >
        <div
          v-if="getImage(i)"
          class="image"
          :style="[{ 'background-image': 'url(' + getImage(i) + ')', }, { 'background-size': getImagesTypesDefined(i) }]"
        />

        <div v-html="$t(`STATEMENT.${$attrs.name}.CHOICE.${choice}`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    labeli18n() {
      return `STATEMENT.${this.$attrs.name}.LABEL`;
    },

    choices() {
      return this.$attrs.choices;
    },

    choicesType() {
      const size = this.choices.length;
      return (size % 2 === 0) ? `odd-${size}` : 'even';
    },
  },

  methods: {
    getImage(i) {
      const images = this.$attrs?.images;
      if (!images) return undefined;
      // eslint-disable-next-line
      return require(`@/assets/images/form/${images[i]}`);
    },

    getImagesTypesDefined(i) {
      if (!this.$attrs?.imagesTypes || !this.$attrs?.images) return 'cover';

      return this.$attrs?.imagesTypes[i];
    },
  },
};
</script>

<style lang="scss" scoped>
.BoxesStatement {
  .label {
    margin-bottom: $spacing-xs;
    font-weight: bold;
    padding: 0 1rem;
    color: #333;
  }

  .boxes {
    gap: $spacing-l;

    &.odd {
      &-2 {
        display: grid;
        @media #{$breakpoint-md-min} {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      &-4 {
        display: grid;
        @media #{$breakpoint-md-min} {
          grid-template-columns: repeat(2, 1fr);
        }
        @media #{$breakpoint-xl-min} {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    &.even {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      @media #{$breakpoint-md-min} {
        flex-direction: row;
      }
    }

    .box {
      flex: 1;
      // border: 1px solid;
      background: #fff;
      display: grid;
      align-content: flex-start;
      gap: $spacing-xs;
      padding: $spacing-l;
      margin-top: $spacing-s;
      box-shadow: $shadow-elevation-1;
      line-height: 1.5;

      .image {
        height: 200px;
        margin-bottom: $spacing-s;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
body {
  counter-reset: box;
}

.counter {
  .boxes .box {
    counter-increment: box;
    position: relative;

    z-index: 1;
  }
  .box::before {
    content: counter(box);
    position: absolute;
    right: 1rem;
    bottom: -1rem;
    font-family: $font-family-secondary;
    font-size: 150px;
    font-weight: bold;
    line-height: 1;
    color: adjust-color($color-primary, $alpha: -0.7);
    z-index: -1;
  }
}
</style>
