<template>
  <div
    class="RadioChoices"
    :style="{'display': direction}"
  >
    <div
      v-for="(subchoices, choice) in choices"
      :key="choice"
      class="choice-block"
    >
      <div
        v-if="getImage(choice -1)"
        class="choice-images"
        :class="{ 'selected': (innerValue === choice) || hasSubchoiceSelected(choice)}"
        @click="selectChoiceImage($event, choice, subchoices)"
      >
        <img
          class="image"
          :src="getImage(choice - 1)"
          alt="logo"
        >
        <div
          v-if="choice < $attrs.choices.length"
          :style="{'background-color': `hsl(${choice}90deg, ${choice}6%,  ${choice}8%)`}"
          class="arrow"
        /> {{ getChoice(choice) }}
      </div>

      <div
        v-else
        class="choice"
        :class="{ 'selected': (innerValue === choice) || hasSubchoiceSelected(choice)}"
        @click="selectChoice($event, choice, subchoices)"
      >
        <div
          v-if="range && choice < $attrs.choices.length"
          class="arrow"
          :style="{'background-color': `hsl(${choice}99deg, 99%, 48%)`}"
        /> {{ getChoice(choice) }}
      </div>

      <div
        v-for="(subchoice, i) in subchoices"
        :key="i"
        class="subchoice-block"
      >
        <div
          class="subchoice"
          :class="{ 'selected': innerValue === `${choice}.${subchoice}` } "
          @click="selectSubchoice($event, `${choice}.${subchoice}`)"
        >
          {{ $t(`QUESTION.${$attrs.name}.CHOICE.${choice}-${subchoice}`) }}
        </div>
      </div>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [ModelMixin, i18nAttributeMixin],

  computed: {
    choices() {
      return this.format(this.$attrs.choices);
    },

    isNumber() {
      return this.$attrs?.subtype && this.$attrs?.subtype === 'number';
    },

    direction() {
      return this.$attrs?.display;
    },

    range() {
      return this.$attrs?.range;
    },

    desc() {
      return this.$attrs?.desc;
    },
  },

  methods: {
    hasSubchoiceSelected(choice) {
      return new RegExp(`^${choice}{1}\\.`, 'g').test(this.innerValue);
    },

    selectChoice({ target }, value, subchoices) {
      this.clearSubchoices();
      this.clearChoices();

      const choice = target.closest('.choice');
      choice.classList.add('selected');

      if (subchoices.length) {
        choice.closest('.choice-block').querySelector('.subchoice').classList.add('selected');
        this.innerValue = `${value}.${subchoices[0]}`;
      } else {
        this.innerValue = value;
      }
    },

    selectChoiceImage({ target }, value, subchoices) {
      this.clearSubchoices();
      this.clearChoices();

      const choice = target.closest('.choice-images');
      choice.classList.add('selected');

      if (subchoices.length) {
        choice.closest('.choice-block').querySelector('.subchoice').classList.add('selected');
        this.innerValue = `${value}.${subchoices[0]}`;
      } else {
        this.innerValue = value;
      }
    },

    selectSubchoice({ target }, value) {
      this.clearSubchoices();
      this.clearChoices();

      const subchoice = target.closest('.subchoice');
      subchoice.classList.add('selected');
      subchoice.closest('.choice-block').querySelector('.choice').classList.add('selected');
      this.innerValue = value;
    },

    clearChoices() {
      const selectedChoice = this.$el.querySelectorAll('.choice.selected');
      if (selectedChoice) {
        Array.from(selectedChoice).map(choice => choice.classList.remove('selected'));
      }
    },

    clearSubchoices() {
      const selectedSubchoice = this.$el.querySelectorAll('.subchoice.selected');
      if (selectedSubchoice) {
        Array.from(selectedSubchoice).map(subchoice => subchoice.classList.remove('selected'));
      }
    },

    format(items) {
      return items.reduce((result, el) => {
        const values = el.toString().split('.');
        const groupName = values[0];
        const accumulator = result;
        if (!accumulator[groupName]) accumulator[groupName] = [];
        if (values[1]) accumulator[groupName].push(values[1]);

        return accumulator;
      }, Object.create(null));
    },

    getChoice(choice) {
      const key = `QUESTION.${this.answerTag}.CHOICE.${choice}`;
      if (this.isNumber) {
        return this.$te(key) ? this.$t(key) : choice.toString();
      }
      return this.$t(key);
    },

    getImage(i) {
      const images = this.$attrs?.images;
      if (!images) return undefined;

      // eslint-disable-next-line
      return require(`@/assets/images/form/${images[i]}`);
    },
  },
};
</script>


<style lang="scss" scoped>
.RadioChoices {
  display: grid;
  // flex-direction: column;
  gap: $spacing-2xs;

  .choice-block {
     .choice,
    .subchoice{
      padding: $spacing-2xs;
      // border-bottom: 1px solid $color-neutral-mid;
      display: flex;
      align-items: center;
      gap: $spacing-xs;

      &:hover{
        cursor: pointer;
        background: $color-primary-lightest;

      }

      &:before {
        content: "";
        background: #fff;
        border-radius: 100%;
        border: 1px solid $color-neutral-mid-dark;
        display: inline-block;
        min-width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        z-index: 2;
      }

      &.selected{
        background: $color-primary-lightest;
        color: $color-primary-darker;

        &:before {
          background-color: $color-primary;
          box-shadow: inset 0 0 0 4px #fff;
          z-index: 2;

        }
      }
    }

    .choice-images,
    .subchoice{
      padding: $spacing-2xs;
      // border-bottom: 1px solid $color-neutral-mid;
      display: flex;
      align-items: center;
      gap: $spacing-xs;

      &:hover{
        cursor: pointer;
        background: $color-primary-lightest;
      }
      &.selected{
        background: $color-primary-lightest;
        color: $color-primary-darker;
        &:before {
          background-color: $color-primary;
          box-shadow: inset 0 0 0 4px #fff;
        }
        .image{
         // width: 4.5rem;
          background: #fff;
          border: solid 1px $color-primary;
          border-radius: 50px;
        }

      }
    }

    .choice{
      //background: $color-neutral-lighter;
      .image{
        width: 3rem;

      }
      .arrow{
          background: orange;
          position: absolute;
          margin-top:3rem;
          height: 2rem;
          width: 0.3rem;
          margin-left: 0.7rem;
          z-index: 1;

        }
    }

    .choice-images{
      background: $color-neutral-lighter;
      border-radius: 50px;
      .image{
        width: 3rem;
         z-index: 2;
      }
       .arrow{
          background: $color-primary;
          position: absolute;
          margin-top:3rem;
          height: 3rem;
          width: 0.4rem;
          margin-left: 1.3rem;
          z-index: 1;
        }
    }

    .subchoice{
      margin-left: $spacing-m;
      //background: $color-neutral-lightest;
    }
  }
}
</style>
