var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Map" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.optionsMapUbication,
              expression: "optionsMapUbication"
            }
          ],
          staticClass: "access"
        },
        [
          _c(
            "BaseButton",
            {
              staticClass: "button",
              attrs: { variation: "secondary" },
              on: {
                click: function($event) {
                  return _vm.allowAccess()
                }
              }
            },
            [
              _c("img", {
                staticClass: "image",
                attrs: { src: require("@/assets/iconos/ubicacion.svg") }
              }),
              _vm._v(" " + _vm._s(_vm.$t("QUESTION.NA23.MY.UBICATION")) + " ")
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.optionsMapSearch,
              expression: "optionsMapSearch"
            }
          ]
        },
        [
          _c(
            "label",
            [
              _c("gmap-autocomplete", {
                staticClass: "inputComplete",
                attrs: {
                  id: "autocomplete",
                  placeholder: _vm.$t("QUESTION.NA23.SITE.UBICATION")
                },
                on: { place_changed: _vm.setPlace }
              })
            ],
            1
          )
        ]
      ),
      _vm.myCoordinates
        ? _c(
            "div",
            { staticClass: "my-ubication" },
            [
              _c("label", { attrs: { for: "Latitud" } }, [
                _vm._v(_vm._s(_vm.$t("LATITUDE")))
              ]),
              _vm._v(" "),
              _c("BaseInput", { attrs: { value: _vm.myCoordinates.lat } }),
              _c("label", { attrs: { for: "Longitud" } }, [
                _vm._v(_vm._s(_vm.$t("LENGTH")))
              ]),
              _vm._v(" "),
              _c("BaseInput", { attrs: { value: _vm.myCoordinates.lng } })
            ],
            1
          )
        : _vm._e(),
      _c(
        "GmapMap",
        {
          ref: "mapRef",
          staticClass: "google-map",
          attrs: {
            center: _vm.centerMap,
            zoom: _vm.zoom,
            "map-type-id": "satellite"
          }
        },
        [
          _vm.access
            ? _c("GmapMarker", {
                attrs: {
                  position: _vm.myCoordinates,
                  clickable: true,
                  draggable: true
                },
                on: {
                  click: function($event) {
                    _vm.center = _vm.myCoordinates
                  },
                  drag: _vm.updateCoordinates
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }