<template>
  <div class="BaseInputFile">
    <div
      class="content"
    >
      <label
        :for="name"
      >
        <span v-if="isEmpty">
          {{ placeholderText }}
        </span>

        <span
          v-for="(file, i) in files"
          v-else
          :key="i"
          class="file"
        >
          {{ file }}
        </span>
      </label>

      <input
        :id="name"
        type="file"
        :accept="accept"
        :name="name"
        multiple="multiple"
        @change="updateFilesNamesList"
      >
    </div>

    <p
      v-if="placeholder"
      class="placeholder"
    >
      {{ placeholder }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    accept: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      files: [],
    };
  },

  computed: {
    name() {
      return `form-${this.tag}`;
    },

    isEmpty() {
      return !(this.files.length > 0);
    },

    placeholderText() {
      return this.placeholder ?? this.$t('INPUTFILE.PLACEHOLDER.DEFAULT');
    },
  },

  methods: {
    updateFilesNamesList() {
      const input = document.getElementById(this.name);
      this.files = Array.from(input.files).map((file, i) => input.files.item(i).name);
      this.$emit('files-selected', input.files);
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseInputFile{
  display: flex;
  margin-bottom: 0.75rem;

  .content{
    flex: 1;
    display: flex;
    border: 1px dashed hsl(210, 7%, 56%);
    background: #f7f7f6;

    span{
      margin: auto;
      padding:  0.75rem;
    }

    label{
      width: 100%;
      color: #999;
      font-size: 0.75rem;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;

      .file{
        box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);;
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        height: 100%;
        text-align: center;
        justify-content: center;
      }
    }

    input{
      display: none;
    }
  }
}

</style>
