<template>
  <TransitionBlowUp>
    <div
      class="BaseModal"
      :class="[`size--${size}`]"
      @click.stop="close($event)"
    >
      <div class="modal">
        <div
          v-if="headerVisible"
          class="modal-header"
        >
          <h1 class="title">
            {{ title }}
          </h1>

          <BaseButton
            size="s"
            variation="tertiary"
            class="close-button"
            @click.native="close($event)"
          >
            x
          </BaseButton>
        </div>

        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </TransitionBlowUp>
</template>

<script>
import TransitionBlowUp from '@/transitions/TransitionBlowUp';

const sizes = ['s', 'm', 'l', 'xl', 'xxl', 'full'];

export default {
  components: {
    TransitionBlowUp,
  },

  props: {
    headerVisible: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: sizes[0],
      validator: size => sizes.includes(size),
    },

    title: {
      type: String,
      default: '',
    },
  },

  methods: {
    close(event) {
      const classList = event.target.classList.value.split(' ');

      if (classList.some(targetClass => ['BaseModal', 'close-button'].includes(targetClass))) {
        this.isClosed = true;
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //background-color: adjust-color($color-primary-darkest, $alpha: -.2);
  display: flex;

  z-index: 20;
  padding: var(--padding);
  overflow-y: auto;
  align-items: var(--local-align);

  .modal {
    flex: 1;
    display: flex;
    flex-direction: column;

    max-width: var(--max-width);
    margin: 0 auto;
    border-radius: var(--border-radius);

    background: #fff;
    box-shadow: var(--shadow-elevation-4);
    overflow-x: auto;

    .modal-body {
      display: flex;
    }

    .modal-header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      background-color: $color-primary;
      padding: $spacing-xs;

      .title{
        color: #fff;
        padding: $spacing-2xs $spacing-s;
        text-transform: uppercase;
        font-weight: 500;
        font-size: $font-size-xl;
      }
    }

    .modal-body {
      background-color: $color-neutral-lightest;
      flex: 1;
      display: flex;

      &::v-deep > * {
        flex: 1;
        width: 100%;
      }
    }
  }

  &.size {
    &--s {
      --padding: #{$spacing-m};
      --max-width: 600px;
      --border-radius: #{$border-radius-s};
      --local-align: start;
    }

    &--m {
      --padding: #{$spacing-2xl} #{$spacing-m};
      --max-width: 800px;
      --border-radius: #{$border-radius-m};
      --local-align: center;
    }

    &--l {
      --padding: #{$spacing-l} #{$spacing-m};
      --max-width: 1000px;
      --border-radius: #{$border-radius-l};
      --local-align: center;
    }

    &--xl {
      --padding: #{$spacing-3xl} #{$spacing-m};
      --max-width: 1200px;
      --border-radius: #{$border-radius-l};
      --local-align: stretch;
    }

    &--full {
      --padding: #{$spacing-2xs};
      --max-width: auto;
      --border-radius: #{$border-radius-m};
      --local-align: stretch;
    }
  }
}
</style>
