<template>
  <div class="ModalStatement">
    <div
      v-if="$te(labeli18n)"
      class="label"
      v-html="$t(labeli18n)"
    />

    <BaseButton
      size="m"
      variation="secondary"
      @click="openModal"
    >
      {{ $t(buttoni18n) }}
    </BaseButton>

    <BaseModal
      v-if="isVisible"
      size="l"
      @close="closeModal"
    >
      <FileStatement v-bind="$attrs" />
    </BaseModal>
  </div>
</template>

<script>
import FileStatement from '@/components/statements/FileStatement';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  components: {
    FileStatement,
  },

  mixins: [i18nAttributeMixin],

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    labeli18n() {
      return `STATEMENT.${this.answerTag}.LABEL`;
    },

    buttoni18n() {
      return `STATEMENT.${this.answerTag}.BUTTON`;
    },
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ModalStatement{
  display: flex;
  align-items: baseline;

  .label{
    margin-right: $spacing-xs;
  }

}
</style>
