<template>
  <div class="RadioBoxes">
    <div
      class="items"
      :style="{gridTemplateColumns: gridColumns}"
    >
      <div
        v-for="(choice, i) in choices"
        :key="i"
        class="content"
      >
        <div
          class="choice"
          :class="{ 'selected': (innerValue === choice.value)}"
          @click="selectChoice($event, choice.value)"
        >
          <div
            v-if="getImage(i)"
            class="image"
            :style="[{ 'background-image': 'url(' + getImage(i) + ')', }, { 'background-size': getImagesTypesDefined(i) }]"
          />

          <span>{{ choice.text }} </span>
        </div>

        <ModalStatement
          v-if="$attrs.files"
          v-bind="{name: getButtonKey(choice.value), src: getFile(i)}"
        />
      </div>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';
import ModalStatement from '@/components/statements/ModalStatement';

const LETTER_SIZE = 12;
const MAX_WIDTH = 300;
export default {
  components: {
    ModalStatement,
  },

  mixins: [ModelMixin, i18nAttributeMixin],

  computed: {
    gridColumns() {
      if (this.choices) {
        const choiceMaxWidth = this.getChoiceMaxWith();
        const autoClass = this.$attrs?.grid === 'fit' ? 'fit' : 'fill';
        let minSize = (choiceMaxWidth * LETTER_SIZE) + LETTER_SIZE * 2;
        minSize = (minSize > MAX_WIDTH) ? MAX_WIDTH : minSize;

        return `repeat(auto-${autoClass}, minmax(${minSize}px, 1fr))`;
      }
      return undefined;
    },

    choices() {
      return this.$attrs.choices.map(choice => ({ value: choice, text: this.getChoice(choice) }));
    },
  },

  methods: {
    getImage(i) {
      const images = this.$attrs?.images;
      if (!images) return undefined;

      // eslint-disable-next-line
      return require(`@/assets/images/form/${images[i]}`);
    },

    getImagesTypesDefined(i) {
      if (!this.$attrs?.imagesTypes || !this.$attrs?.images) return 'cover';

      return this.$attrs?.imagesTypes[i];
    },

    getFile(i) {
      return this.$attrs?.files[i];
    },

    getChoiceMaxWith() {
      const length = this.choices.reduce((acc, choice) => ((choice.text.length > acc) ? choice.text.length : acc), 0);
      return length; // > MAX_WIDTH ? MAX_WIDTH : length;
    },

    selectChoice({ target }, value) {
      this.clearChoices();

      const choice = target.closest('.choice');
      choice.classList.add('selected');
      this.innerValue = value;
    },

    clearChoices() {
      const selectedChoice = this.$el.querySelectorAll('.choice.selected');
      if (selectedChoice) {
        Array.from(selectedChoice).map(choice => choice.classList.remove('selected'));
      }
    },

    getChoice(choice) {
      const key = `QUESTION.${this.answerTag}.CHOICE.${choice}`;
      if (this.$attrs?.subtype && this.$attrs?.subtype === 'number') {
        return this.$te(key) ? this.$t(key) : choice.toString();
      }
      return this.$t(key);
    },

    getButtonKey(choice) {
      return this.i18n ? `${this.answerTag}` : `${this.answerTag}.CHOICE.${choice}`;
    },
  },
};
</script>


<style lang="scss" scoped>
.RadioBoxes {
  display: grid;
  gap: $spacing-2xs;

  .items{
    display: grid;
    gap: $spacing-xs;

    .content{
      display: grid;
      grid-template-columns: 100%;
      gap: $spacing-2xs;

      .choice{
        background: $color-neutral-light;
        padding: $spacing-s;
        border: 1px solid $color-neutral-mid;
        border-radius: 5px;
        display: grid;
        align-content: center;
        text-align: center;
        gap: $spacing-xs;
        background: #fff;
        transition: all .5s ease;
        box-shadow: $shadow-elevation-1;

        .image {
          height: 150px;
          margin-bottom: $spacing-s;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 5px;
        }

        &:hover{
          cursor: pointer;
          border-color: $color-neutral-light;
        }

        &.selected {
          box-shadow: inset 0 0 0 2px $color-primary;
          background: $color-primary-lighter;
          animation: heartbeat 1.5s ease-in-out both;
        }
      }

      .ModalStatement{
        margin: auto;
      }
    }

  }
}
</style>
