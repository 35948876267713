import { render, staticRenderFns } from "./BaseTableCustom.vue?vue&type=template&id=0ce0c400&scoped=true&"
import script from "./BaseTableCustom.vue?vue&type=script&lang=js&"
export * from "./BaseTableCustom.vue?vue&type=script&lang=js&"
import style0 from "./BaseTableCustom.vue?vue&type=style&index=0&id=0ce0c400&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce0c400",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ce0c400')) {
      api.createRecord('0ce0c400', component.options)
    } else {
      api.reload('0ce0c400', component.options)
    }
    module.hot.accept("./BaseTableCustom.vue?vue&type=template&id=0ce0c400&scoped=true&", function () {
      api.rerender('0ce0c400', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/BaseTableCustom.vue"
export default component.exports