<template>
  <div class="ImageStatement">
    <div
      v-if="image"
      class="image"
      :style="[{ 'background-image': 'url(' + image + ')', }, { 'background-size': imageType }]"
    />
  </div>
</template>

<script>
export default {
  computed: {
    image() {
      const image = this.$attrs?.image;
      if (!image) return undefined;
      // eslint-disable-next-line
      return require(`@/assets/images/form/${image}`);
    },

    imageType() {
      if (!this.$attrs?.imageType || !this.$attrs?.image) return 'cover';

      return this.$attrs?.imageType;
    },
  },
};
</script>

<style lang="scss" scoped>
.ImageStatement{
  .image{
    height: 100%;
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
