var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ModalStatement" },
    [
      _vm.$te(_vm.labeli18n)
        ? _c("div", {
            staticClass: "label",
            domProps: { innerHTML: _vm._s(_vm.$t(_vm.labeli18n)) }
          })
        : _vm._e(),
      _c(
        "BaseButton",
        {
          attrs: { size: "m", variation: "secondary" },
          on: { click: _vm.openModal }
        },
        [_vm._v(" " + _vm._s(_vm.$t(_vm.buttoni18n)) + " ")]
      ),
      _vm.isVisible
        ? _c(
            "BaseModal",
            { attrs: { size: "l" }, on: { close: _vm.closeModal } },
            [
              _c(
                "FileStatement",
                _vm._b({}, "FileStatement", _vm.$attrs, false)
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }