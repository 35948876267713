var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MenuTooltipFooter" }, [
    _c(
      "a",
      {
        on: {
          click: function($event) {
            return _vm.gotoHome()
          }
        }
      },
      [
        _c("font-awesome-icon", { attrs: { icon: ["fas", "home"] } }),
        _c("div", [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.HOME")) + " ")
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.HOME")) + " ")
          ])
        ])
      ],
      1
    ),
    _c(
      "a",
      {
        on: {
          click: function($event) {
            return _vm.gotoProfile()
          }
        }
      },
      [
        _c("font-awesome-icon", { attrs: { icon: ["fas", "user"] } }),
        _c("div", [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.PROFILE")) + " ")
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.SETTINGS")) + " ")
          ])
        ])
      ],
      1
    ),
    _vm.user && _vm.user.profile === 2
      ? _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.gotoReport()
              }
            }
          },
          [
            _c("font-awesome-icon", { attrs: { icon: ["fas", "poll"] } }),
            _c("div", [
              _c("p", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.REPORT")) + " ")
              ]),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.RESULT")) + " ")
              ])
            ])
          ],
          1
        )
      : _vm._e(),
    _c(
      "a",
      {
        on: {
          click: function($event) {
            return _vm.gotoHelp()
          }
        }
      },
      [
        _c("font-awesome-icon", { attrs: { icon: ["fas", "question"] } }),
        _c("div", [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.HELP")) + " ")
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.SECTION.HELP")) + " ")
          ])
        ])
      ],
      1
    ),
    _c(
      "a",
      {
        on: {
          click: function($event) {
            return _vm.logout()
          }
        }
      },
      [
        _c("font-awesome-icon", { attrs: { icon: ["fas", "door-open"] } }),
        _c("div", [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("HEADER.LOGOUT.TITLE")) + " ")
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("HEADER.LOGOUT.DESCRIPTION")) + " ")
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }