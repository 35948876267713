var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MenuTooltipBody" }, [
    _vm.user && _vm.user.avatar != ""
      ? _c("div", {
          staticClass: "profile",
          style: { backgroundImage: "url(" + _vm.avatar + ")" }
        })
      : _c(
          "div",
          { staticClass: "profile" },
          [
            _c("font-awesome-icon", {
              attrs: { size: "2x", icon: ["fas", "user"] }
            })
          ],
          1
        ),
    _c("div", [
      _c("p", { staticClass: "name" }, [
        _vm._v(" " + _vm._s(_vm.name) + " "),
        _c("span", { staticClass: "type" }, [_vm._v(_vm._s(_vm.type))])
      ]),
      _c("p", { staticClass: "email" }, [_vm._v(" " + _vm._s(_vm.email) + " ")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }