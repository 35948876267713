/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: false,
  courses: undefined,
  centers: undefined,
  allusers: undefined,
  infouser: undefined,
  reports: undefined,
  coursescenter: undefined,
  centerwhithcourse: undefined,
  centerwhithstudent: undefined,
  coursewhithstudent: undefined,
  usersPrograms: undefined,
  stats: undefined
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,

  getCourses: state => () => state.courses,

  getCenters: state => () => state.centers,

  getAllusers: state => () => state.allusers,

  getUsersPrograms: state => () => state.usersPrograms,

  getInfoUser: state => () => state.infouser,

  getReports: state => () => state.reports,

  getCoursescenter: state => centerId => state?.coursescenter?.filter(center => center.centerId === centerId),

  getCenterwhithcourse: state => () => state.centerwhithcourse,

  getCenterwhithstudent: state => () => state.centerwhithstudent,

  getCoursewhithstudent: state => () => state.coursewhithstudent,

  getStats: state => () => state.stats,

  getFilterProgram: state => reportId => state?.reports?.filter(report => report.programId === reportId),

  getFilterRegister: state => typeRegisterId => state?.allusers?.filter(user => user.typeRegister === typeRegisterId),

  getFilterUserByCenter: state => centerId => state?.allusers?.filter(user => user.center === centerId),

  getFilterUserByCourse: state => courseId => state?.allusers?.filter(user => user.course === courseId),

  getFilterUserCenterAndCourse: state => params => state?.allusers?.filter(user => user.center === params.center && user.course === params.course)
};

export const mutations = {
  ...make.mutations(state)
};

export const actions = {
  async fetchCourses({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/courses');
      if (error) throw new Error(data);

      commit('SET_COURSES', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchCenters({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/centers');
      if (error) throw new Error(data);

      commit('SET_CENTERS', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchCentersWhitStudent({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/center-whith-student');
      if (error) throw new Error(data);

      commit('SET_CENTERWHITHSTUDENT', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchCoursesWhitStudent({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/courses-whith-student');
      if (error) throw new Error(data);

      commit('SET_COURSEWHITHSTUDENT', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addCenter({ commit }, { name, description, code, province }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/add-center', {
        name,
        description,
        code,
        province
      });
      if (error) throw new Error(data);

      commit('SET_CENTERS', data.centers);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async editCenter({ commit }, { id, name, description, code, province }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/edit-center', {
        id,
        name,
        description,
        code,
        province
      });
      if (error) throw new Error(data);

      commit('SET_CENTERS', data.centers);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeStateCenter({ commit }, { id, name, description, state, code, province }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/edit-center', {
        id,
        name,
        description,
        state,
        code,
        province
      });
      if (error) throw new Error(data);

      commit('SET_CENTERS', data.centers);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addCourse({ commit }, { name, description }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/add-course', { name, description });
      if (error) throw new Error(data);

      commit('SET_COURSES', data.courses);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async editCourse({ commit }, { id, name, description }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/edit-course', { id, name, description });
      if (error) throw new Error(data);

      commit('SET_COURSES', data.courses);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeStateCourse({ commit }, { id, name, description, state }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/edit-course', {
        id,
        name,
        description,
        state
      });
      if (error) throw new Error(data);

      commit('SET_COURSES', data.courses);
      window.Vue.$toast.success(data.message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAllUsers({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/all-users');
      if (error) throw new Error(data);

      commit('SET_ALLUSERS', data?.allUser);
      commit('SET_USERS_PROGRAMS', data?.usersProgram);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchInfoUser({ commit }, userId) {
    try {
      commit('SET_LOADING', true);
      const { data, error } = await axios.get(`/info-user/${userId}`);
      if (error) throw new Error(data);
      commit('SET_INFOUSER', data.user);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchReportUser({ commit }, userId) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/report-user-id/${userId}`);

      if (error) throw new Error(data);
      commit('SET_REPORTS', data.reports);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAllCourseCenter({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/courses-center');
      if (error) throw new Error(data);

      commit('SET_COURSESCENTER', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchCenterWhithCourse({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/center-register');
      if (error) throw new Error(data);

      commit('SET_CENTERWHITHCOURSE', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchStats({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/statics');
      if (error) throw new Error(data);

      commit('SET_STATS', data);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async changeStateUser({ commit }, request) {
    try {
      commit('SET_LOADING', true);

      const { data, error, message } = await axios.post('/delete-user', request);
      if (error) throw new Error(data);

      commit('SET_ALLUSERS', data);
      window.Vue.$toast.success(message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateUser({ commit }, request) {
    try {
      commit('SET_LOADING', true);

      const { data, error, message } = await axios.post('/update-user-admin', request);
      if (error) throw new Error(data);

      commit('SET_ALLUSERS', data);
      window.Vue.$toast.success(message);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line consistent-return
  async downloadReport({ commit }, locale) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/report_excel_programs/${locale}`);
      if (error) throw new Error(data);
      // window.Vue.$toast.success(message);

      return data;
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
