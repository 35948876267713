var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MenuContentResponsive" },
    [
      _c("TransitionSlide", [
        _c(
          "ul",
          { staticClass: "panel" },
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "li",
              { key: i },
              [
                _c("router-link", { attrs: { to: "/" + item.link } }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ]),
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v(" " + _vm._s(item.description) + " ")
                  ])
                ])
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }