<template>
  <div class="MultipleChoices">
    <div
      v-for="(subchoices, choice) in choices"
      :key="choice"
      class="choice-block"
    >
      <BaseModal
        v-show="isModalVisible"
        :header-visible="true"
        :title="titleModal"
        size="m"
        @close="close"
      >
        <ViewImageExample :route="routeImage" />
      </BaseModal>

      <div class="divChoice">
        <div
          class="choice"
          :class="{ 'selected': hasChoiceSelected(choice) || hasSubchoiceSelectedByChoice(choice)}"
          @click="selectChoice($event, choice, subchoices)"
        >
          {{ $t(`QUESTION.${($attrs.i18n || $attrs.name)}.CHOICE.${choice}`) }}
        </div>

        <div
          v-if="getExistImage(choice - 1)"
          class="icon"
        >
          <font-awesome-icon
            :icon="['fas', 'eye']"
            @click="openModal(choice - 1)"
          />
        </div>
      </div>

      <div
        v-for="(subchoice, i) in subchoices"
        :key="i"
        class="subchoice-block"
      >
        <div
          class="subchoice"
          :class="{ 'selected': hasChoiceSelected(`${choice}.${subchoice}`) }"
          @click="selectSubchoice($event, `${choice}.${subchoice}`)"
        >
          {{ $t(`QUESTION.${$attrs.i18n || $attrs.name}.CHOICE.${choice}-${subchoice}`) }}
        </div>
      </div>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
import ViewImageExample from '@/components/common/ViewImageExample';

export default {
  components: {
    ViewImageExample,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      choices: undefined,
      isModalVisible: false,
      routeImage: '',
      titleModal: '',
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  created() {
    this.choices = this.format(this.$attrs.choices);
  },

  methods: {
    format(items) {
      return items.reduce((result, el) => {
        const values = el.toString().split('.');
        const groupName = values[0];
        const accumulator = result;
        if (!accumulator[groupName]) accumulator[groupName] = [];
        if (values[1]) accumulator[groupName].push(values[1]);

        return accumulator;
      }, Object.create(null));
    },

    hasSubchoiceSelectedByChoice(choice) {
      return this.innerValue.some(value => new RegExp(`^${choice}{1}\\.`, 'g').test(value));
    },

    hasChoiceSelected(subchoice) {
      // return this.innerValue.some(value => new RegExp(`\\.${subchoice}{1}$`, 'g').test(value));
      return this.innerValue.some(value => value === subchoice);
    },

    selectChoice({ target }, value, subchoices) {
      const subchoicesFormatted = subchoices.map(subchoice => `${value}.${subchoice}`);
      const choice = target.closest('.choice');

      if (choice.classList.contains('selected')) {
        this.removeFromChoice(choice, value, subchoicesFormatted);
      } else {
        this.addFromChoice(choice, value, subchoicesFormatted);
      }
    },

    removeFromChoice(choice, value, subchoices) {
      choice.classList.remove('selected');
      const selectedSubchoices = choice.closest('.choice-block').querySelectorAll('.subchoice.selected');
      Array.from(selectedSubchoices).map(selectedSubchoice => selectedSubchoice.classList.remove('selected'));

      this.innerValue = this.innerValue.filter(innerValue => !((innerValue === value) || subchoices.includes(innerValue)));
    },

    addFromChoice(choice, value, subchoices) {
      choice.classList.add('selected');
      const selectedSubchoices = choice.closest('.choice-block').querySelectorAll('.subchoice');
      Array.from(selectedSubchoices).map(selectedSubchoice => selectedSubchoice.classList.add('selected'));

      this.innerValue = [...this.innerValue, value, ...subchoices];
    },

    selectSubchoice({ target }, value) {
      const subchoice = target.closest('.subchoice');
      const choice = subchoice.closest('.choice-block').querySelector('.choice');

      if (subchoice.classList.contains('selected')) {
        this.removeFromSubchoice(choice, value, subchoice);
      } else {
        this.addFromSubchoice(choice, value, subchoice);
      }
    },

    removeFromSubchoice(choice, value, subchoice) {
      subchoice.classList.remove('selected');

      const selectedSubchoices = choice.closest('.choice-block').querySelectorAll('.subchoice.selected');
      if (!selectedSubchoices.length) choice.classList.remove('selected');

      this.innerValue = this.innerValue.filter(innerValue => innerValue !== value);
    },

    addFromSubchoice(choice, value, subchoice) {
      subchoice.classList.add('selected');

      if (!choice.classList.contains('selected')) choice.classList.add('selected');

      this.innerValue = [...this.innerValue, value];
    },
    openModal(i) {
      this.isModalVisible = true;
      const images = this.$attrs?.images;
      if (!images) return undefined;
      this.titleModal = this.$t(`QUESTION.${this.$attrs.name}.CHOICE.${i + 1}`);
      // eslint-disable-next-line
 return this.routeImage = require(`@/assets/${this.$attrs?.route}${images[i]}`);
      // return require(`@/assets/${this.$attrs?.route}${images[i]}`);
    },

    close() {
      this.isModalVisible = false;
    },

    getImage(i) {
      const images = this.$attrs?.images;
      if (!images) return undefined;

      // eslint-disable-next-line
      return require(`${this.$attrs?.route}${images[i]}`);
    },

    getExistImage(i) {
      const images = this.$attrs?.images;
      if (images && images[i]) {
        return true;
      }
      return false;
    },


  },
};
</script>


<style lang="scss" scoped>
.MultipleChoices {
  display: flex;
  flex-direction: column;

  .choice-block {
    .choice,
    .subchoice{
      padding: $spacing-2xs;
    // border-bottom: 1px solid $color-neutral-mid;
      display: flex;
      align-items: center;
      gap: $spacing-xs;

      &:hover{
        cursor: pointer;
        background: $color-primary-lightest;
      }

      &:before {
        content: "";
        background: #fff;
        border: 1px solid $color-neutral-mid-dark;
        display: inline-block;
        min-width: 1rem;
        height: 1rem;

        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }

      &.selected:before {
        background-color: $color-primary;
        box-shadow: inset 0 0 0 4px #fff;
      }
    }
.divChoice{
  display: flex;
  .choice{
      flex: 1;
     // background: $color-neutral-light;

    }
     .icon{
       padding-left: $spacing-xl;
      color: $color-primary;
      display: flex;
      text-align: right;
     /*  margin-left: 90%; */
      margin-bottom: 0.5rem;
      margin-top:0.5rem;
      cursor: pointer;
      font-size: $font-size-xl;


    }
}


    .subchoice{
      margin-left: $spacing-m;
     // background: $color-neutral-lighter;
    }


  }
}
</style>
