<template>
  <div class="ViewImageExample">
    <img
      :src="route"
      alt=""
    >
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: String,
      default: '',
    },
  },

};
</script>

<style lang="scss" scoped>
.ViewImageExample{
  img{
    width: 100%;
    max-height: 40rem;
  }
}

</style>
