<template>
  <ul class="MenuContent">
    <li
      v-for="(item, i) in items"
      :key="i"
    >
      <router-link :to="`/${item.link}`">
        {{ item.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuContent{
  display: block;
  transition: left .5s ease;
  height: $theHeaderSpacing;
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;

  li{
    a {
      position: relative;
      color: $color-neutral-lightest;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $font-size-s;
      padding: $spacing-xs $spacing-m;
      transition: all 0.6s ease;
      line-height: $theHeaderSpacing;

      &.disabled {
        pointer-events: none;
        color: $color-neutral-mid;
        text-decoration: line-through;
      }

      &:before {
        content: "";
        transition: all 0.6s ease;
        @extend %underline;
        right: 100%;
      }

      &:hover {
        &:before {
          right: $spacing-m;
        }
      }
    }
  }


  %underline {
    left: $spacing-m;
    bottom: 0;
    border-bottom: 3px solid $color-primary;
    position: absolute;
  }

  .router-link-exact-active:before {
    @extend %underline;
    right: $spacing-m;
  }

}
</style>
