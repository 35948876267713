<template>
  <div
    v-show="!isLoading"
    class="TheHeader"
    :class="{'change-color': scrollPosition > 50}"
  >
    <div class="menu">
      <!--  <router-link
        v-if="isAuthenticated"
        to="/"
      >
        <img
          :src="require(`@/assets/images/aztertu_completo.png`)"
          alt="logo"
        >
      </router-link> -->


      <Menu
        v-if="isAuthenticated && user && user.profile === 1"
        :user="user"
      />

      <MenuTooltip
        v-if="isAuthenticated"
        :user="user"
      />

      <ChangeLanguage
        v-if="isAuthenticated"
        :user="user"
      />
      <Language
        v-else
        class="language"
      />
      <!--  <MenuAccess v-else /> -->
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import Menu from '@/components/header/Menu';
import MenuTooltip from '@/components/header/MenuTooltip';
/* import MenuAccess from '@/components/header/MenuAccess'; */
import ChangeLanguage from '@/components/profile/ChangeLanguage';
import Language from '@/components/header/Language';


export default {
  name: 'TheHeader',
  components: {
    Menu,
    MenuTooltip,
    /*  MenuAccess, */
    ChangeLanguage,
    Language,
  },

  data() {
    return {
      scrollPosition: null,
    };
  },

  computed: {
    ...get('authModule', [
      'isLoading',
      'isAuthenticated',
      'getUser',
    ]),

    user() {
      return this.getUser();
    },
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    if (this.isAuthenticated && !this.getUser()) {
      this.$store.dispatch('authModule/fetchUser');
    }
  },


  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped lang="scss">
.TheHeader {
  height: $theHeaderSpacing;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;

  display: flex;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: $shadow-elevation-1;
  transition: all 0.3s ease;

  &.change-color {
    background-color:black
  }

  .menu {
    width: 100%;
    padding: 0 $spacing-m;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    gap: $spacing-2xs;
    font-family: $font-family-secondary;

    .language{
      margin-left: auto;
      margin-right: 1rem;
    }

    & > * {
      padding: 0 $spacing-xs;
    }

    .logo-container {
      display: grid;
      align-items: center;
      background: #fff;

      img {
        max-height: 40px;
      }
    }

    .MenuTooltip,
    .MenuAccess {
      margin-left: auto;
    }
  }

  @media #{$breakpoint-md-max} {
    .menu-logged .logo-container img {
      max-width: 150px;
    }
  }

  // @media #{$breakpoint-sm-max} {
  //   --local-header-fixed-height: 58px;
  // }
}
  @media print{
      .TheHeader{
        display: none;
      }
  }
</style>
