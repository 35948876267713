var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MultipleChoices" },
    [
      _vm._l(_vm.choices, function(subchoices, choice) {
        return _c(
          "div",
          { key: choice, staticClass: "choice-block" },
          [
            _c(
              "BaseModal",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModalVisible,
                    expression: "isModalVisible"
                  }
                ],
                attrs: {
                  "header-visible": true,
                  title: _vm.titleModal,
                  size: "m"
                },
                on: { close: _vm.close }
              },
              [_c("ViewImageExample", { attrs: { route: _vm.routeImage } })],
              1
            ),
            _c("div", { staticClass: "divChoice" }, [
              _c(
                "div",
                {
                  staticClass: "choice",
                  class: {
                    selected:
                      _vm.hasChoiceSelected(choice) ||
                      _vm.hasSubchoiceSelectedByChoice(choice)
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectChoice($event, choice, subchoices)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "QUESTION." +
                            (_vm.$attrs.i18n || _vm.$attrs.name) +
                            ".CHOICE." +
                            choice
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _vm.getExistImage(choice - 1)
                ? _c(
                    "div",
                    { staticClass: "icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "eye"] },
                        on: {
                          click: function($event) {
                            return _vm.openModal(choice - 1)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._l(subchoices, function(subchoice, i) {
              return _c("div", { key: i, staticClass: "subchoice-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "subchoice",
                    class: {
                      selected: _vm.hasChoiceSelected(choice + "." + subchoice)
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectSubchoice(
                          $event,
                          choice + "." + subchoice
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "QUESTION." +
                              (_vm.$attrs.i18n || _vm.$attrs.name) +
                              ".CHOICE." +
                              choice +
                              "-" +
                              subchoice
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            })
          ],
          2
        )
      }),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }