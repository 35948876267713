var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "Menu" },
    [
      _vm.responsive
        ? _c(
            "div",
            {
              staticClass: "menu-bars",
              on: {
                click: function($event) {
                  return _vm.toggleVisibility()
                }
              }
            },
            [
              !_vm.opened
                ? _c("font-awesome-icon", { attrs: { icon: ["fas", "bars"] } })
                : _c("font-awesome-icon", { attrs: { icon: ["fas", "times"] } })
            ],
            1
          )
        : _vm._e(),
      !_vm.responsive
        ? _c("MenuContent", { attrs: { items: _vm.items } })
        : _vm.responsive && _vm.opened
        ? _c("MenuContentResponsive", {
            attrs: { items: _vm.items },
            nativeOn: {
              click: function($event) {
                return _vm.closeContentResponsive($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }