import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'; // your vuex store

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const requiredAuthenticated = (to, from, next) => {
  if (store.getters['authModule/isAuthenticated']) {
    next();
  } else {
    next({ name: 'Login' });
  }
};

const redirectIfAuthenticated = (to, from, next) => {
  if (store.getters['authModule/isAuthenticated']) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView'),
    beforeEnter: redirectIfAuthenticated,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/DashboardView'),
  },
  {
    name: 'About',
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView'),
  },
  {
    name: 'Plans',
    path: '/plans',
    component: () => import(/* webpackChunkName: "plans" */ '@/views/PlansView'),
  },
  {
    name: 'Law',
    path: '/law',
    component: () => import(/* webpackChunkName: "law" */ '@/views/LawView.vue'),
  },
  {
    name: 'Clients',
    path: '/clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientsView'),
  },
  {
    name: 'Reports',
    path: '/reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/ReportsView'),
  },
  {
    name: 'Report',
    path: '/report/:reportId',
    props: route => ({ ...route.params, reportId: +route.params.reportId }),
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ReportView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'ProgramIntro',
    path: '/program/:programId/name/:name',
    props: route => ({ ...route.params, programId: +route.params.programId, name: +route.params.name }),
    component: () => import(/* webpackChunkName: "Program" */ '@/views/ProgramIntroView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Program',
    path: '/program/:programId/name/:name/reportId/:reportId',
    props: route => ({
      ...route.params, programId: +route.params.programId, name: +route.params.name, reportId: +route.params.reportId,
    }),
    component: () => import(/* webpackChunkName: "Program" */ '@/views/ProgramView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'PanelAdmin',
    path: '/panel-admin',
    component: () => import(/* webpackChunkName: "Map" */ '@/views/PanelAdminView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'UserAdmin',
    path: '/user-admin',
    component: () => import(/* webpackChunkName: "Map" */ '@/views/UserAdminView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Centers',
    path: '/centers',
    component: () => import(/* webpackChunkName: "Map" */ '@/views/CentersView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Courses',
    path: '/courses',
    component: () => import(/* webpackChunkName: "Map" */ '@/views/CoursesView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'ReportByUser',
    path: '/reportByUser/:userId',
    props: route => ({ ...route.params, userId: +route.params.userId }),
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ReportByUserView.vue'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Mapa',
    path: '/map',
    component: () => import(/* webpackChunkName: "Map" */ '@/views/GoogleMapView'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'ProgramsNatura',
    path: '/programs/natura',
    props: route => ({ ...route.params, programId: +route.params.programId, name: +route.params.name }),
    component: () => import(/* webpackChunkName: "Program" */ '@/views/ProgramsNatura.vue'),
    beforeEnter: requiredAuthenticated,
  },
  {
    name: 'Help',
    path: '/help',
    component: () => import(/* webpackChunkName: "Help" */ '@/views/HelpView'),
    beforeEnter: requiredAuthenticated,
  },

  {
    name: 'Message',
    path: '/Message/:reportId',
    props: route => ({ ...route.params, reportId: +route.params.reportId }),
    component: () => import(/* webpackChunkName: "Message" */ '@/views/MessageFinish'),
    beforeEnter: requiredAuthenticated,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
