var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ImageStatement" }, [
    _vm.image
      ? _c("div", {
          staticClass: "image",
          style: [
            { "background-image": "url(" + _vm.image + ")" },
            { "background-size": _vm.imageType }
          ]
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }