var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "MenuContent" },
    _vm._l(_vm.items, function(item, i) {
      return _c(
        "li",
        { key: i },
        [
          _c("router-link", { attrs: { to: "/" + item.link } }, [
            _vm._v(" " + _vm._s(item.name) + " ")
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }