/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  report: undefined,
  blocks: undefined,
  answers: undefined,
  program: undefined,
  reportsuser: undefined,
  dataUser: undefined,
  loading: false,
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,

  getReport: state => () => state.report,

  getBlocks: state => () => state.blocks,

  getAnswers: state => () => state.answers,

  getProgram: state => () => state.program,

  getBlock: state => blockId => state.blocks.find(block => block.id === blockId),

  getReportsuser: state => () => state.reportsuser,

  getFilterProgram: state => reportId => state?.reportsuser?.filter(report => report.programId === reportId),

  getDataUser: state => () => state.dataUser,
};

export const mutations = {
  ...make.mutations(state),
};

export const actions = {
  async fetchReport({ commit }, reportId) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/result/reports/${reportId}`);
      if (error) throw new Error(data);

      commit('SET_BLOCKS', data.blocks);
      commit('SET_PROGRAM', data.nameProgram);
      commit('SET_DATA_USER', data?.user);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchReportUser({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/reports-user');
      if (error) throw new Error(data);
      commit('SET_REPORTSUSER', data.reports);
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
