var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CalculatedStatement" }, [
    _c("span", { staticClass: "result" }, [
      _vm._v(" " + _vm._s(_vm.result || "--") + " ")
    ]),
    _vm.units ? _c("span", [_vm._v(_vm._s(_vm.units))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }