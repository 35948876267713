var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseTableCustom" }, [
    _c("div", { staticClass: "thead" }, [
      _c(
        "div",
        { staticClass: "tr" },
        _vm._l(_vm.headers, function(head, i) {
          return _c("div", {
            key: i,
            staticClass: "th",
            domProps: { innerHTML: _vm._s(head) }
          })
        }),
        0
      )
    ]),
    _c(
      "div",
      { staticClass: "tbody" },
      _vm._l(_vm.body, function(row, i) {
        return _c(
          "div",
          { key: i, staticClass: "tr" },
          [
            _vm._l(row, function(field, j) {
              return _c("div", { key: j, staticClass: "td" }, [
                !_vm.isObject(field)
                  ? _c("div", [_vm._v(" " + _vm._s(field) + " ")])
                  : _c(
                      "div",
                      [
                        _c(
                          _vm.questionType(field.type),
                          _vm._g(
                            _vm._b(
                              {
                                key: field.name,
                                tag: "component",
                                attrs: {
                                  answer:
                                    field.type === "calculated"
                                      ? _vm.answer
                                      : undefined
                                },
                                on: {
                                  input: function($event) {
                                    _vm.answerTableCell(
                                      field.name,
                                      $event && $event.target
                                        ? $event.target.value
                                        : $event
                                    )
                                  }
                                },
                                model: {
                                  value: field.value,
                                  callback: function($$v) {
                                    _vm.$set(field, "value", $$v)
                                  },
                                  expression: "field.value"
                                }
                              },
                              "component",
                              _vm.cellAttributes(field),
                              false
                            ),
                            _vm.$listeners
                          )
                        )
                      ],
                      1
                    )
              ])
            }),
            _vm.areSlot("actions-" + i)
              ? _c("div", { staticClass: "td" }, [_vm._t("actions-" + i)], 2)
              : _vm._e()
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }