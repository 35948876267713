<template>
  <div class="BaseTable">
    <table>
      <thead>
        <tr
          v-for="(head, i) in headers"
          :key="i"
        >
          <th
            v-for="(column, j) in head"
            :key="j"
            :rowspan="column.row ? column.row : headers.length"
            :colspan="column.col ? column.col : 1"
          >
            {{ getHeadersName(column) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, i) in paginatedBody"
          :key="i"
        >
          <td
            v-for="(field, j) in row"
            :key="j"
          >
            <span v-if="j!=='avatar'">{{ field }}</span>
            <p
              v-if="j==='avatar' && field !== ''"
              :style="{backgroundImage:'url('+routeAvatar+field+')'}"
              class="avatar"
            />
            <p
              v-if="j==='avatar' && field === ''"
              class="avatar"
            />
          </td>

          <td v-if="areSlot(`actions-${i}`)">
            <slot :name="`actions-${i}`" />
          </td>
        </tr>

        <tr
          v-if="paginatedBody.length === 0"
          class="empty-tr"
        >
          <td :colspan="headersLength">
            No hay información
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="maxValue"
      class="paginationContainer"
    >
      <span
        class="pagination-control prev"
        :class="{disabled: pagination.current < 1}"
        @click="setPage(0)"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-left']"
        />
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-left']"
        />
      </span>
      <span
        class="pagination-control prev"
        :class="{disabled: pagination.current < 1}"
        @click="prevPage()"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-left']"
        />
      </span>
      <span
        v-for="page in pages"
        :key="'page' + page"
        class="pagination-control page"
        :class="{active: page === pagination.current}"
        @click="setPage(page)"
      >{{ page + 1 }}</span>
      <span
        class="pagination-control next"
        :class="{disabled: paginatedBody.length < pagination.size}"
        @click="nextPage"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-right']"
        />
      </span>
      <span
        class="pagination-control next"
        :class="{disabled: paginatedBody.length < pagination.size}"
        @click="setPage(maxValue)"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-right']"
        />
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-right']"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },

    body: {
      type: Array,
      default: undefined,
    },

    pagination: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      pages: [],
      paginatedBody: [],
      currentPage: 0,
    };
  },

  computed: {
    headersLength() {
      return this.headers.reduce((arr, header) => arr + header.filter(item => typeof item === 'string').length, 0);
    },

    routeAvatar() {
      return '/uploads/users/avatar/';
    },

    maxValue() {
      return this.pagination ? Math.max(Math.ceil(this.body.length / (this.pagination.size || 1)) - 1, 0) : 0;
    },
  },

  watch: {
    body() {
      this.setPage(0);
    },
  },

  mounted() {
    this.setPage(0);
  },

  methods: {
    areSlot(tag) {
      return !!this.$slots[tag];
    },

    getHeadersName(th) {
      return (typeof th === 'object' && th !== null) ? th.name : th;
    },

    setPage(page) {
      this.currentPage = page;
      if (this.pagination) this.pagination.current = page;

      this.generatePages();
      this.updateData();

      this.$emit('set-page', page);
    },

    generatePages() {
      this.pages = [];
      if (!this.pagination) return;

      const Max = Math.min(this.pagination.current + 2, this.maxValue);

      if (this.pagination.current > Max) this.pagination.current = 0;

      for (let i = Math.max(this.pagination.current - 2, 0); i <= Max; i += 1) this.pages = [...this.pages, i];
    },

    updateData() {
      if (!this.pagination) this.paginatedBody = [...this.body];
      else {
        const page = this.pagination.current * this.pagination.size;
        this.paginatedBody = [...this.body].slice(page, page + this.pagination.size);
      }
    },

    prevPage() {
      if (this.pagination.current > 0) this.setPage(this.pagination.current - 1);
    },

    nextPage() {
      if (this.pagination.current < this.maxValue) this.setPage(this.pagination.current + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseTable {
  table {
    width: 100%;

    th,
    td {
      vertical-align: middle;
      padding: $spacing-xs $spacing-s;
      text-align: center;
      font-size: $font-size-s;
      width: 100px;
    }

    td {
      border-bottom: 1px solid $color-neutral-mid-dark;

      &:first-child {
        text-align: left;
      }
    }

    thead {
      background: $color-primary-darker;
      color: $color-neutral-light;

      th {
        font-weight: 500;
        border-bottom: 2px solid $color-neutral-light;
        &:not(:first-child) {
          border-left: 2px solid $color-neutral-light;
        }

        &:not(:last-child) {
          border-right: 2px solid $color-neutral-light;
        }
      }
    }

    tbody tr {
      transition: all 0.2s ease;
      &:nth-child(odd) {
        background: adjust-color($color-neutral-lightest, $lightness: 2%);
      }
      &:nth-child(even) {
        background: adjust-color($color-neutral-lightest, $lightness: 1%);
      }
      &:hover {
        background: adjust-color($color-primary-lightest, $lightness: 2%);
      }
    }

    tbody tr.empty-tr {
      background: $color-neutral-light;
      td {
        padding: $spacing-xl $spacing-m;
        text-align: center;
        border-bottom: none;
      }
    }
  }

  .avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/images/profile-user.png');
    background-size: cover;
    margin-left: 2rem;
    border: solid 1px#fff;
  }

  .paginationContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto 0;
    gap: 0.25rem;
    justify-content: flex-end;
    user-select: none;

    .pagination-control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      aspect-ratio: 1;
      cursor: pointer;
      color: #1e88e5;

      &.page {
        padding-top: 4px;

        &.active {
          color: white;
          background-color: #1e88e5;
          border-radius: 3px;
        }
      }

      &.disabled {
        color: #78909c;
      }
    }
  }
}
</style>
