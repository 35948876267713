var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UploadFile" },
    [
      _c(
        "div",
        { staticClass: "camera" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fas", "camera"] },
            on: {
              click: function($event) {
                return _vm.$refs.inputFile.click()
              }
            }
          })
        ],
        1
      ),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: 0, expression: "0" }
        ],
        ref: "inputFile",
        attrs: { type: "file", accept: "image/*" },
        on: {
          change: function($event) {
            return _vm.loadImage($event)
          }
        }
      }),
      _c("BaseInputFile", {
        staticClass: "buttonFile",
        attrs: { tag: "file", accept: _vm.accept },
        nativeOn: {
          change: function($event) {
            return _vm.loadImage($event)
          }
        }
      }),
      _vm.typeFile === "image"
        ? _c("div", {
            staticClass: "preview",
            style: { backgroundImage: "url(" + _vm.currentImage + ")" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }