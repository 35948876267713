<template>
  <div class="BaseTableCustom">
    <div class="thead">
      <div class="tr">
        <div
          v-for="(head, i) in headers"
          :key="i"
          class="th"
          v-html="head"
        />
      </div>
    </div>

    <div class="tbody">
      <div
        v-for="(row, i) in body"
        :key="i"
        class="tr"
      >
        <div
          v-for="(field, j) in row"
          :key="j"
          class="td"
        >
          <div v-if="!isObject(field)">
            {{ field }}
          </div>

          <div v-else>
            <component
              :is="questionType(field.type)"
              :key="field.name"
              v-model="field.value"
              :answer="(field.type === 'calculated') ? answer : undefined"
              v-bind="cellAttributes(field)"
              @input="answerTableCell(field.name, ($event && $event.target) ? $event.target.value : $event)"
              v-on="$listeners"
            />
          </div>
        </div>

        <div
          v-if="areSlot(`actions-${i}`)"
          class="td"
        >
          <slot :name="`actions-${i}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statement from '@/components/statements/Statement';
import RadioChoices from '@/components/questions/RadioChoices';
import MultipleChoices from '@/components/questions/MultipleChoices';
import CalculatedStatement from '@/components/statements/CalculatedStatement';

const QUESTIONS_TYPES = {
  input: 'BaseInput',
  choice: 'RadioChoices',
  choices: 'MultipleChoices',
  select: 'BaseSelect',
  statement: 'Statement',
  calculated: 'CalculatedStatement',
};

export default {
  components: {
    Statement,
    RadioChoices,
    MultipleChoices,
    CalculatedStatement,
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },

    body: {
      type: Array,
      default: undefined,
    },

    answer: {
      type: Object,
      default: undefined,
    },
  },

  mounted() {
    // console.log(this.headers);
  },

  methods: {
    areSlot(tag) {
      return !!this.$slots[tag];
    },

    isObject(field) {
      return (typeof field === 'object' && field !== null);
    },

    cellAttributes({ attributes, name }) {
      return Object.assign({}, attributes, { name });
    },

    answerTableCell(key, value) {
      // console.log(key, value);
      this.$emit('set-value', { key, value });
    },

    questionType(type) {
      return (typeof QUESTIONS_TYPES[type] !== 'undefined') ?
        QUESTIONS_TYPES[type] :
        QUESTIONS_TYPES.input;
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseTableCustom{
  width: 100%;
  overflow: auto;

  .th, .td{
    padding: $spacing-xs $spacing-s;
    text-align: center;
    font-size: $font-size-s;
  }

  .th{
    display: grid;
    align-items: center;
  }

  .td{
    border-bottom: 1px solid $color-neutral-mid-dark;
    display: grid;
    align-items: center;
    overflow: auto;

    &:first-child{
      text-align: left;
    }

    ::v-deep .GeneratedStatement{
      justify-content: flex-end;
    }
  }

  .tr{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .thead{
    background: $color-primary-darker;
    color: $color-neutral-light;

    .th{
      font-weight: 500;
      border-bottom: 1px solid $color-neutral-light;
      &:not(:first-child){
        border-left: 1px solid $color-neutral-light;
      }

      &:not(:last-child){
        border-right: 1px solid $color-neutral-light;
      }
    }
  }

  .tbody .tr{
    transition: all .2s ease;
    &:nth-child(odd){
      background: adjust-color($color-neutral-lightest, $lightness: 2%);
    }
    &:nth-child(even){
      background: adjust-color($color-neutral-lightest, $lightness: 1%);
    }
    &:hover{
      background: adjust-color($color-primary-lightest, $lightness: 2%);
    }
  }
}
</style>
