<template>
  <div class="MultipleBoxes">
    <div
      class="items"
      :style="{gridTemplateColumns: gridColumns}"
    >
      <div
        v-for="(choice, i) in choices"
        :key="i"
        class="choice"
        :class="{ 'selected': hasChoiceSelected(choice.value) }"
        @click="selectChoice($event, choice.value)"
      >
        {{ choice.text }}
      </div>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
const LETTER_SIZE = 12;
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      choices: undefined,
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    gridColumns() {
      if (this.choices) {
        const choiceMaxWidth = this.getChoiceMaxWith();
        const autoClass = this.$attrs?.grid === 'fit' ? 'fit' : 'fill';
        const minSize = (choiceMaxWidth * LETTER_SIZE) + LETTER_SIZE * 2;
        return `repeat(auto-${autoClass}, minmax(${minSize}px, 1fr))`;
      }
      return undefined;
    },
  },

  created() {
    this.choices = this.$attrs.choices.map(choice => ({ value: choice, text: this.getChoice(choice) }));
  },

  methods: {
    getChoiceMaxWith() {
      return this.choices.reduce((acc, choice) => ((choice.text.length > acc) ? choice.text.length : acc), 0);
    },

    hasChoiceSelected(subchoice) {
      return this.innerValue.some(value => value === subchoice);
    },

    selectChoice({ target }, value) {
      const choice = target.closest('.choice');
      if (choice.classList.contains('selected')) {
        this.removeFromChoice(choice, value);
      } else {
        this.addFromChoice(choice, value);
      }
    },

    removeFromChoice(choice, value) {
      choice.classList.remove('selected');
      this.innerValue = this.innerValue.filter(innerValue => innerValue !== value);
    },

    addFromChoice(choice, value) {
      choice.classList.add('selected');
      this.innerValue = [...this.innerValue, value];
    },

    getChoice(choice) {
      const key = `QUESTION.${this.$attrs.name}.CHOICE.${choice}`;
      if (this.$attrs?.subtype && this.$attrs?.subtype === 'number') {
        return this.$te(key) ? this.$t(key) : choice;
      }
      return this.$t(key);
    },
  },
};
</script>


<style lang="scss" scoped>
.MultipleBoxes {
  display: grid;
  gap: $spacing-2xs;

  .items{
    display: grid;
    gap: $spacing-2xs;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .choice{
      background: $color-neutral-light;
      padding: $spacing-s;
      border: 1px solid $color-neutral-mid;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      transition: all .5s ease;

      &:hover{
        cursor: pointer;
        border-color: $color-neutral-light;
      }

      &.selected {
        box-shadow: inset 0 0 0 2px $color-primary;
        background: $color-primary-lighter;
        animation: heartbeat 1.5s ease-in-out both;
      }
    }
  }
}
</style>
