<template>
  <div
    class="MenuTooltip"
    @click.stop="toogleActive($event)"
  >
    <div
      v-if="user && user.avatar != ''"
      class="avatar"
      :style="{backgroundImage:'url('+avatar+')'}"
    />

    <div
      v-else
      class="profile"
    >
      <font-awesome-icon
        size="lg"
        :icon="['fas', 'user']"
      />
    </div>

    <p class="caret-down">
      <font-awesome-icon
        size="lg"
        :icon="['fas', 'caret-down']"
      />
    </p>

    <div class="content">
      <div
        class="content-sections"
      >
        <MenuTooltipBody
          v-if="user"
          :user="user"
        />

        <MenuTooltipFooter :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import MenuTooltipBody from '@/components/header/MenuTooltipBody';
import MenuTooltipFooter from '@/components/header/MenuTooltipFooter';


export default {
  name: 'Menutooltip',

  components: {
    MenuTooltipBody,
    MenuTooltipFooter,
  },

  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...get('messageModule', [
      'getMailsReceivedNotRead',
    ]),

    getMailsNotRead() {
      return this.getMailsReceivedNotRead()?.length;
    },

    avatar() {
      return `uploads/users/avatar/${this.user?.avatar}`;
    },
  },

  async created() {
    window.addEventListener('resize', this.calculeContentPosition);
  },

  updated() {
    this.calculeContentPosition();
  },

  mounted() {
    const headerHeight = document.querySelector('.TheHeader')?.offsetHeight;
    if (headerHeight) {
      const content = this.$el.querySelector('.content');
      content.style.top = `${headerHeight}px`;
    } else {
      const content = this.$el.querySelector('.content');
      content.style.top = '50px';
    }

    this.calculeContentPosition();
  },

  methods: {
    calculeContentPosition() {
      const content = this.$el.querySelector('.content');
      if (content) {
        const header = document.querySelector('.TheHeader');
        const headerWidth = header?.offsetWidth ?? 0;
        const headerHeight = header?.offsetHeight ?? 0;
        const isWider = headerWidth - 1440;
        const offset = 9;
        const right = ((isWider > offset) ? (isWider / 2) : offset);

        const maxHeight = window.innerHeight - headerHeight - (offset * 2);
        content.style.right = `${right}px`;
        content.style.maxHeight = `${maxHeight}px`;
      }
    },

    toogleActive({ target }) {
      const isContent = target.classList.contains('content');
      const isFooterLink = (target.closest('.MenuTooltipFooter a') !== null);
      const isContentElement = (target.closest('.content') !== null);
      const isActive = this.$el.classList.contains('active');

      if (isFooterLink || isContent || (!isContentElement && isActive)) {
        this.$el.classList.remove('active');
      } else {
        this.$el.classList.add('active');
      }
    },
  },
};
</script>

<style lang="scss">
.MenuTooltip {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-2xs;

  &:hover{
    cursor: pointer;
  }

   .profile {
    --local-image-size: 50px;
    height: 100%;
    width: var(--local-image-size);
    // border-radius: 50%;
    // border: 2px solid $color-neutral-mid-light;
    background-color: $color-primary-darkest;
    // background-size: cover;
    color: #fff;
    display: grid;
    place-content: center;
  }


   .avatar {
    --local-image-size: 50px;
    height: 100%;
    width: var(--local-image-size);
     border-radius: 50%;
    background-color: $color-primary-darkest;
    background-size: cover;
    border: solid 1px #fff;

  }

  .caret-down{
    color: #fff;
  }

  .content {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    right: $spacing-xs;
    left: $spacing-xs;
    margin-left: auto;
    margin-top: 8px;
    max-width: 450px;
    color: $color-neutral-dark;

    transition: visibility .6s ease, opacity .6s ease, right 0s linear;

    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 8rem;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }

    &::after {
      content: "";
      position: fixed;
      top: $theHeaderSpacing;
      left: 0;
      right: 0;
      bottom: 0;
      background: hsla(0, 0%, 0%, 0.2);
      z-index: -1;
    }

    .content-sections{
      flex: 1;
      overflow-y: auto;
      box-shadow: $shadow-elevation-2;
      background: #fff;
    }

    cursor: initial;
  }

  &.active .content{
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  @media #{$breakpoint-sm-max} {
    .profile {
      --local-image-size: 40px;
    }
  }
}
</style>
