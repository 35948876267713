<template>
  <footer>
    <div class="footer window-fit">
      <!--   <p class="develop-by">
        Desarrollado por Gestionet Multimedia
      </p> -->
      <div>
        <!--    <a
          href="https://gestionet.net/"
          target="blank"
        ><img src="@/assets/images/logo_Gestionet.jpg"></a> -->
        <!--   <a
          href="https://www.euskadi.eus/informacion/programa-aztertu/web01-a2inghez/es/"
          target="blank"
        > <img
          class="fondo"
          src="@/assets/images/aztertu_completo.png"
        ></a> -->
        <!--  <a
          href="https://www.euskadi.eus/informacion/centros-ingurugela/web01-a2inghez/es/"
          target="blank"
        > <img
          class="fondo"
          src="@/assets/images/INGURUGELA N.jpg"
        ></a> -->
        <a
          href="https://www.euskadi.eus/gobierno-vasco/educacion-ambiental/"
          target="blank"
        > <img
            class="ih"
            src="@/assets/images/logo_geemis.jpg"
          ></a>
        <a
          href="https://www.euskadi.eus/inicio/"
          target="blank"
        > <img
            class="fondo"
            src="@/assets/images/gv-vasco-ambiente.png"
          ></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>


<style lang="scss" scoped>
footer {
  background-color: #fff;
  box-shadow: $shadow-elevation-4;

  .footer {
    color: white;
    display: flex;
    /*  gap: $spacing-m;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    padding: $spacing-m; */

    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding: 1rem;

    img {
      width: 15rem;

      @media #{$breakpoint-md-max} {
        width: 12rem;
      }
    }

    .fondo {
      width: 25rem;
      background: #fff;
    }

    .aztertu {
      padding-top: 3rem;
    }
    .ih {
      height: 5rem;
      width: 23rem;
    }

    .develop-by {
      font-size: $font-size-s;
    }

    .contact {
      align-self: flex-start;
      font-size: $font-size-s;
      justify-self: end;

      p {
        display: grid;
        gap: $spacing-xs;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        font-size: $font-size-xs;
        color: $color-neutral-light;

        b {
          font-weight: 500;
        }
      }

      a {
        color: white;
        transition: color 0.5s ease;
        cursor: pointer;

        &:hover {
          color: $color-primary-lighter;
        }
      }
    }
  }
}

@media print {
  footer {
    display: none;
  }
}
</style>
