var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BoxesStatement" }, [
    _vm.$te(_vm.labeli18n)
      ? _c("p", {
          staticClass: "label",
          domProps: { innerHTML: _vm._s(_vm.$t(_vm.labeli18n)) }
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "boxes", class: [_vm.choicesType] },
      _vm._l(_vm.choices, function(choice, i) {
        return _c("div", { key: i, staticClass: "box" }, [
          _vm.getImage(i)
            ? _c("div", {
                staticClass: "image",
                style: [
                  { "background-image": "url(" + _vm.getImage(i) + ")" },
                  { "background-size": _vm.getImagesTypesDefined(i) }
                ]
              })
            : _vm._e(),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("STATEMENT." + _vm.$attrs.name + ".CHOICE." + choice)
              )
            }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }