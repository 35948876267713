<template>
  <div class="TableCustom">
    <div
      v-if="hasDinamicallyColumns || hasDinamicallyRow"
      class="action"
    >
      <div
        v-if="hasDinamicallyColumns"
        class="add-button"
      >
        <BaseInput
          v-model="newColumn.value"
          v-bind="newColumn"
        />

        <BaseButton
          size="s"
          @click="addNewColumn(newColumn.value)"
        >
          <font-awesome-icon
            :icon="['fas', 'plus']"
          />
        </BaseButton>
      </div>

      <div
        v-if="hasDinamicallyRow"
        class="add-button"
      >
        <BaseInput
          v-model="newRow.value"
          v-bind="newRow"
        />

        <BaseButton
          size="s"
          @click="addNewRow(newRow.value)"
        >
          <font-awesome-icon
            :icon="['fas', 'plus']"
          />
        </BaseButton>
      </div>
    </div>

    <BaseTableCustom
      v-if="contents"
      :headers="headers"
      :body="contents"
      :answer="value"
      @set-value="setValue"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  data() {
    return {
      newColumn: {
        type: 'text',
        name: 'column',
        placeholder: 'Nombre de columna',
        value: undefined,
      },

      newRow: {
        type: 'text',
        name: 'row',
        placeholder: 'Nombre de fila',
        value: undefined,
      },
      headers: undefined,
      contents: undefined,

      hiddenColumns: [],
    };
  },

  computed: {
    ...get('indicatorModule', [
      'getReport',
      'getAnswerByQuestion',
    ]),

    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    name() {
      return this.$attrs.name;
    },

    dinamically() {
      return this.$attrs?.dinamically;
    },

    hasDinamicallyColumns() {
      if (!this.dinamically) return false;

      return this.dinamically.match(/column/g);
    },

    hasDinamicallyRow() {
      if (!this.dinamically) return false;

      return this.dinamically.match(/row/g);
    },
    // inputAttributes() {
    //   return Object.assign({}, this.$attrs, { label: undefined });
    // },
    baseYear() {
      const report = this.getReport();
      return report?.activity;
    },

    head() {
      return this.$attrs.head;
    },

    body() {
      return this.$attrs.body;
    },

    customHeaders() {
      if (!this.value) return [];

      return Object.entries(this.value).filter(value => /\.H\d+/g.test(value[0])).map(value => value[1]);
    },

    bodyAnswers() {
      if (!this.value) return [];

      return Object.entries(this.value).filter(value => !(/\.H\d+/g.test(value[0])));
    },

    unfixedRowsNumber() {
      const fixedRows = this.body.filter(body => !body.fixed);
      return fixedRows.reduce((acc, item) => acc + parseInt(item.number, 10), 0);
    },

    fixedRowsNumber() {
      const fixedRows = this.body.filter(body => body.fixed);
      return fixedRows.reduce((acc, item) => acc + parseInt(item.number, 10), 0);
    },

    fixedRowsItem() { // only for one fixed row
      const fixedRow = this.body.find(body => body.fixed);
      return fixedRow?.items[1];
    },
  },

  created() {
    this.loadTable();
  },

  methods: {
    loadTable() {
      this.loadHeaders();
      this.loadBody();
    },

    loadHeaders() {
      this.headers = this.head.map((head) => {
        if (head === '') return head;

        const newHead = `${this.name}.${head}`;
        return (/YEAR/g.test(head)) ? this.$t(newHead, [this.baseYear]) : this.$t(newHead);
      });

      if (this.value) {
        const newColumnsKeys = this.customHeaders;
        newColumnsKeys.forEach((columnName) => {
          this.insertColumnDependingFixedColumns(columnName);
        });
      }
    },

    loadBody() {
      const mainBody = this.body.filter(body => body.main);
      this.contents = mainBody.reduce((acc, body) => {
        const row = [...Array(body.number).keys()].map((i) => {
          let count = 0;
          return body.items.reduce((acc2, item) => {
            const result = acc2;
            const rowNumber = acc.length + i;
            const name = `${this.name}.R${rowNumber}.C${count}`;

            let newColumn = Object.assign({}, item, { name, value: undefined });

            if (!body.fixed && newColumn.type === 'calculated') {
              newColumn = this.newCalculatedColumn(newColumn, rowNumber);
            }

            if (!newColumn.hidden) {
              result[name] = newColumn;
              count += 1;
            } else {
              this.hiddenColumns.push(newColumn);
            }
            return result;
          }, {});
        });

        return [...acc, ...row];
      }, []);

      this.loadDependsOnColumns();

      this.loadCustomColmunRows();
      this.loadCustomRows();

      this.loadBodyAnswers();
    },

    loadDependsOnColumns() {
      const columns = this.dynamicColumnsDependingAnswer();
      const sizeNumber = this.headers.length;
      for (let i = 0; i < columns; i += 1) {
        const headers = this.getDuplicatedUnfixedHeaders((sizeNumber - 1) + i);
        headers.forEach((header) => {
          this.addColumn();
          this.insertColumnDependingFixedColumns(header);
        });
      }
    },

    dynamicColumnsDependingAnswer() {
      const questionKey = this.$attrs?.columns;
      if (!questionKey) return 0;

      const answer = this.getAnswerByQuestion(null, questionKey);
      return parseInt(answer?.answer, 10);
    },

    getDuplicatedUnfixedHeaders(number) {
      // todo get number of fixedColumn instead of subtracting one
      const endSlice = this.hasColumnFixed() ? (this.head.length - 1) : this.head.length;
      const headers = this.head.slice(1, endSlice);

      return headers.map(head => this.$t(`${this.name}.${head}`, [(this.baseYear + number)]));
    },

    generateChoices(item) {
      if (!/(select)|(choice)/g.test(item)) return undefined;
      const choicesNumberExec = /\d+/gm.exec(item);
      if (!choicesNumberExec) return undefined;

      const choicesNumber = parseInt(choicesNumberExec[0], 10);
      return [...Array(choicesNumber).keys()];
    },

    loadCustomColmunRows() {
      for (let i = 0; i < this.customHeaders.length; i += 1) {
        this.addNewColumn();
      }
    },

    getHigherCustomBodyRow() {
      if (!this.value) return 0;

      return Object.keys(this.value).filter(value => /\.R\d+\.C0/g.test(value)).reduce((acc, value) => {
        const row = value.split('.')[1].replace('R', '');
        const rowNumber = parseInt(row, 10);
        return (rowNumber > acc) ? rowNumber : acc;
      }, 0);
    },

    loadCustomRows() {
      const higherCustomBodyRow = this.getHigherCustomBodyRow();
      if (higherCustomBodyRow > (this.contents.length - 1)) {
        const numberOfNewRows = higherCustomBodyRow - (this.contents.length - 1);
        for (let i = 0; i < numberOfNewRows; i += 1) {
          this.addRow();
        }
      }
    },

    loadBodyAnswers() {
      this.bodyAnswers.forEach((answer) => {
        const answerKey = answer[0];
        const answerValue = answer[1];
        const contentIndex = this.contents.findIndex(content => content[answerKey] !== undefined);

        this.contents[contentIndex][answerKey].value = answerValue;
      });
    },

    addNewColumn(columnName) {
      this.addColumn();

      if (columnName) {
        const newValue = { [`${this.name}.H${Object.keys(this.contents[0]).length}`]: columnName };
        this.innerValue = (this.value) ? Object.assign(this.value, newValue) : newValue;
        this.insertColumnDependingFixedColumns(columnName);
      }
    },

    insertColumnDependingFixedColumns(columnName) {
      if (this.hasColumnFixed()) {
        this.headers.splice(this.headers.length - 1, 0, columnName);
      } else {
        this.headers.push(columnName);
      }
    },

    hasColumnFixed() {
      const firstBodyItems = this.body[0].items;
      const rowItems = Object.keys(firstBodyItems);
      const lastColumnKey = rowItems[rowItems.length - 1];
      const lastColumnItem = firstBodyItems[lastColumnKey];
      return (lastColumnItem?.fixed);
    },

    addColumn() {
      this.contents = this.contents.map((content, i, arr) => {
        let item = (this.fixedRowsNumber > 0 && (i > (arr.length - 1) - this.fixedRowsNumber)) ? this.fixedRowsItem : this.body[0].items[1];

        if (this.hiddenColumns.length > 0) {
          item = Object.assign({}, this.hiddenColumns[0]);
          delete item.hidden;
        }

        const contentKeys = Object.keys(content);
        const rowKey = contentKeys[0];
        const name = rowKey.replace(/\.C\d+/g, `.C${contentKeys.length}`);
        const newColumn = Object.assign({}, item, { name, value: undefined });

        const lastColumnKey = contentKeys[contentKeys.length - 1];
        const lastColumnItem = content[lastColumnKey];
        if (lastColumnItem?.fixed) {
          const contentCopy = content;
          delete contentCopy[lastColumnKey];

          if (lastColumnItem.type === 'calculated') {
            const { operation } = lastColumnItem.attributes;
            const sign = operation.match(/(\/|\+|\*|-)/g);
            lastColumnItem.attributes.operation = lastColumnItem.attributes.operation.concat(sign[0], name);
          }

          return Object.assign(contentCopy, { [name]: newColumn }, { [lastColumnKey]: lastColumnItem });
        }

        if (lastColumnItem.type === 'calculated') {
          const operation = newColumn.attributes.operation.replace(/\.C\d+/g, `.C${contentKeys.length}`);
          newColumn.attributes = Object.assign({}, newColumn.attributes, { operation });
        }

        return Object.assign({}, content, { [name]: newColumn });
      });
    },

    addNewRow(rowName) {
      this.addRow();

      const rowNumber = this.contents.length - 1;
      const key = `${this.name}.R${rowNumber}.C0`;
      const newValue = { [key]: rowName };
      this.innerValue = (this.value) ? Object.assign(this.value, newValue) : newValue;
      const contentIndex = this.contents.findIndex(content => content[key] !== undefined);
      this.contents[contentIndex][key].value = rowName;
    },

    addRow() {
      const rowNumber = this.contents.length;
      const rowStructure = Object.values(this.contents[0]);

      const emptyRow = rowStructure.reduce((acc, column, i) => {
        const name = `${this.name}.R${rowNumber}.C${i}`;

        let newColumn;
        if (i === 0) {
          newColumn = { name, type: 'text', component: 'BaseInput' };
        } else {
          newColumn = Object.assign({}, column, { name, value: undefined });

          if (newColumn.type === 'calculated') {
            newColumn = this.newCalculatedColumn(newColumn, rowNumber);
          }

          if (this.fixedRowsNumber > 0) {
            this.rewriteFixedCalculatedCellOperationColumn(i, name);
          }
        }

        return Object.assign(acc, { [name]: newColumn });
      }, {});

      this.contents.splice(this.contents.length - this.fixedRowsNumber, 0, emptyRow);
    },

    newCalculatedColumn(newColumn, rowNumber) {
      const attributes = Object.assign({}, newColumn.attributes);
      attributes.operation = attributes.operation.replaceAll(/R\d+/g, `R${rowNumber}`);
      return Object.assign({}, newColumn, { attributes });
    },

    rewriteFixedCalculatedCellOperationColumn(columNumber, addedColumnName) {
      const fixedrowNumber = this.fixedRowsNumber + this.unfixedRowsNumber - 1;
      const fixedCellKey = `${this.name}.R${fixedrowNumber}.C${columNumber}`;
      const fixedCell = this.contents[fixedrowNumber][fixedCellKey];
      if (fixedCell.type === 'calculated') {
        const { operation } = fixedCell.attributes;
        const sign = operation.match(/(\/|\+|\*|-)/g);
        fixedCell.attributes.operation = fixedCell.attributes.operation.concat(sign[0], addedColumnName);
        this.contents[fixedrowNumber][fixedCellKey] = fixedCell;
      }
    },

    setValue(value) {
      if ((value.value === undefined) && value.key in this.value) {
        delete this.value[value.key];
        this.innerValue = this.value;
      } else {
        const newValue = { [value.key]: value.value };
        this.innerValue = (this.value) ? Object.assign(this.value, newValue) : newValue;

        const contentIndex = this.contents.findIndex(content => content[value.key] !== undefined);
        this.contents[contentIndex][value.key].value = value.value; // ?
      }
      console.log('value', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.TableCustom{
  width: 100%;

  .action{
    margin-bottom: $spacing-xs;
    display: grid;
    justify-content: end;
    // background: $color-neutral-mid;
  }

  .add-button{
    // border: 1px solid red;
    padding: $spacing-2xs;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    gap: $spacing-s;
    max-width: 600px;
  }
}
</style>
