var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MultipleBoxes" },
    [
      _c(
        "div",
        {
          staticClass: "items",
          style: { gridTemplateColumns: _vm.gridColumns }
        },
        _vm._l(_vm.choices, function(choice, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "choice",
              class: { selected: _vm.hasChoiceSelected(choice.value) },
              on: {
                click: function($event) {
                  return _vm.selectChoice($event, choice.value)
                }
              }
            },
            [_vm._v(" " + _vm._s(choice.text) + " ")]
          )
        }),
        0
      ),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }