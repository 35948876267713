var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SwitchableStatement" }, [
    _vm.$te(_vm.labeli18n)
      ? _c("p", { staticClass: "label" }, [
          _vm._v(" " + _vm._s(_vm.$t(_vm.labeli18n)) + " ")
        ])
      : _vm._e(),
    _c(
      "ul",
      { staticClass: "choices" },
      _vm._l(_vm.$attrs.choices, function(choice, i) {
        return _c(
          "li",
          {
            key: i,
            staticClass: "choice",
            class: { active: i === 0 },
            on: {
              click: function($event) {
                return _vm.selectChoice(i, $event)
              }
            }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("STATEMENT." + _vm.$attrs.name + ".CHOICE." + choice)
                ) +
                " "
            )
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "boxes" },
      _vm._l(_vm.$attrs.choices, function(choice, i) {
        return _c(
          "SimpleStatement",
          _vm._b(
            { key: i, staticClass: "box", class: { active: i === 0 } },
            "SimpleStatement",
            {
              name: _vm.$attrs.name + ".CHOICETEXT." + choice,
              image: _vm.getImage(i)
            },
            false
          )
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }